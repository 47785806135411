export const SETTING_LOGIN_TYPE_PIN = 'pin'
export const SETTING_LOGIN_TYPE_CREDENTIALS = 'credentials'

export const SETTING_LOGIN_TYPES = {
  PIN: SETTING_LOGIN_TYPE_PIN,
  CREDENTIALS: SETTING_LOGIN_TYPE_CREDENTIALS
}

export const SETTING_LOCALES = ['en', 'fr', 'es']
export const SETTING_COMPANY_DEFAULT_PHONE = ''
export const SETTING_COMPANY_DEFAULT_LOGO = 'img/logos/logo_tt.png'
export const SETTING_TRACKTIK_DEFAULT_LOGO = 'img/logos/logo_tt.png'
export const SETTING_TRACKTIK_GREY_LOGO = 'img/logos/logo_tt_grey.png'

export class Setting {
  constructor(payload) {
    Object.assign(this, payload)
  }

  static fromInitialState(state = {}) {
    return new Setting({
      langs: SETTING_LOCALES,
      langDefault: [...SETTING_LOCALES].shift(),
      loginType: SETTING_LOGIN_TYPE_PIN,
      phoneNumber: SETTING_COMPANY_DEFAULT_PHONE,
      logoCompany: SETTING_COMPANY_DEFAULT_LOGO,
      logoTracktik: SETTING_TRACKTIK_DEFAULT_LOGO,
      logoTracktikGrey: SETTING_TRACKTIK_GREY_LOGO,
      ...state
    })
  }
}
