export default {
  name: 'KioskAppOrientation',
  functional: true,
  render(h, context) {
    if ('orientation' in window.screen) {
      let orientation = window.screen.orientation

      window.screen.orientation.addEventListener('change', (event) => {
        orientation = window.screen.orientation
      })

      const isLandscape = orientation.type.startsWith('landscape')
      const isPortrait = orientation.type.startsWith('portrait')
      const isPrimary = orientation.type.endsWith('primary')
      const isSecondary = orientation.type.endsWith('secondary')

      return context.scopedSlots.default({
        orientation,
        isLandscape,
        isPortrait,
        isPrimary,
        isSecondary
      })
    } else {
      return context.scopedSlots.default({
        orientation: null,
        isLandscape: false,
        isPortrait: false,
        isPrimary: false,
        isSecondary: false
      })
    }
  }
}
