import Vue from 'vue'
import Router from 'vue-router'

import Layout from '@/layout'

Vue.use(Router)

export const routes = [
  {
    path: '/404',
    component: Layout,
    children: [
      {
        path: '',
        component: () => import('@/views/error/404'),
        name: 'errors.404',
        meta: {
          layoutToolbar: {
            component: () => import('@/views/toolbars/KioskBasicToolbar'),
            items: {}
          }
        }
      }
    ]
  },
  {
    path: '/',
    redirect: '/dial'
  },
  {
    path: '/admin',
    component: Layout,
    redirect: '/admin/index',
    meta: {
      layout: 'Dialog'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/admin/index'),
        name: 'admin.index',
        meta: {
          roles: ['admin:*', 'admin.auth']
        }
      },
      {
        path: 'auth',
        component: () => import('@/views/admin/auth'),
        name: 'admin.auth'
      },
      {
        path: 'settings',
        component: () => import('@/views/admin/settings'),
        name: 'admin.settings',
        meta: {
          roles: ['kiosk/app/settings', 'admin.auth']
        }
      }
    ]
  },
  {
    path: '/dial',
    component: Layout,
    redirect: '/dial/index',
    meta: {
      layout: 'Page',
      layoutToolbar: {
        component: () => import('@/views/toolbars/KioskBasicToolbar'),
        items: {
          logo: true,
          helpDesk: true,
          setting: true,
          clock: true,
          locale: true
        }
      }
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/dial/index'),
        name: 'dial.index'
      },
      {
        path: 'credentials',
        component: () => import('@/views/dial/credentials'),
        name: 'dial.setting'
      },
      {
        path: 'pin',
        component: () => import('@/views/dial/pin'),
        name: 'dial.pin',
        meta: {
          layoutToolbar: {
            component: () => import('@/views/toolbars/KioskBasicToolbar')
          }
        }
      }
    ]
  },
  {
    path: '/install',
    component: Layout,
    redirect: '/install/index',
    meta: {
      roles: ['install:*', 'dial.index'],
      layout: 'Page',
      layoutToolbar: {
        component: () => import('@/views/toolbars/KioskBasicToolbar'),
        items: {
          logo: true,
          clock: true,
          locale: true
        }
      }
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/install/index'),
        name: 'install.index'
      },
      {
        path: 'settings',
        component: () => import('@/views/install/settings'),
        name: 'install.settings'
      },
      {
        path: 'done',
        component: () => import('@/views/install/done'),
        name: 'install.done'
      }
    ]
  },
  {
    path: '/shift-control',
    component: Layout,
    redirect: '/shift-control/upcoming',
    meta: {
      roles: ['shift-control:*', 'dial.index'],
      layoutToolbar: {
        component: () => import('@/views/toolbars/KioskBasicToolbar'),
        height: '100%',
        items: {
          logo: true,
          helpDesk: true,
          clock: true
        }
      }
    },
    children: [
      {
        path: 'upcoming',
        component: () => import('@/views/shift-control/index'),
        name: 'shift-control.index'
      }
    ]
  },
  { path: '*', redirect: '/404' }
]

export const createRouter = (options = {}) =>
  new Router({
    scrollBehavior(to, from, savedPosition) {
      return { x: 0, y: 0 }
    },
    routes
  })

export const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter(options) {
  const newRouter = createRouter(options)
  router.matcher = newRouter.matcher
}

export default router
