<template>
  <KioskAppHeap :version="version">
    <KioskAppOrientation>
      <template #default="orientation">
        <slot
          :orientation="orientation"
          :version="version"
          :last-update="lastUpdate"
        />
      </template>
    </KioskAppOrientation>
  </KioskAppHeap>
</template>

<script>
import KioskAppOrientation from './KioskAppOrientation'
import { enableWakeLock } from '@/lib/kiosk'
import KioskAppHeap from './KioskAppHeap'

export default {
  name: 'KioskApp',
  components: {
    KioskAppOrientation,
    KioskAppHeap
  },
  props: {
    version: {
      type: String,
      default: process.env.VUE_APP_PKG_VERSION
    },
    lastUpdate: {
      type: Date,
      default() {
        return new Date(parseInt(process.env.VUE_APP_PKG_BUILD_TIMESTAMP))
      }
    }
  },
  mounted() {
    this.checkProtocolOrRedirect()
  },
  created() {
    this.$releaseWakeLock = enableWakeLock()
  },
  destroyed() {
    this.$releaseWakeLock()
  },
  methods: {
    checkProtocolOrRedirect() {
      const targetProtocol = `${process.env.VUE_APP_KIOSK_REST_PROTOCOL}:`
      if (targetProtocol === window.location.protocol) {
        return
      }
      if (process.env.NODE_ENV === 'production') {
        window.location.protocol = `${process.env.VUE_APP_KIOSK_REST_PROTOCOL}:`
        return
      }
      this.$log.debug(
        `VUE_APP_KIOSK_REST_PROTOCOL(${targetProtocol}) is not equal to window.location.protocol(${window.location.protocol})`
      )
    }
  }
}
</script>
