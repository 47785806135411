import * as ClockIn from './clock-in'
import * as ClockOut from './clock-out'
import * as DisapproveClockOutAction from './disapprove-clock-out'
import * as EarlyClockIn from './early-clock-in'
import * as EndBreak from './end-break'
import * as StartBreak from './start-break'
import * as StartBreakMeal from './start-break-meal'
import * as StartBreakRest from './start-break-rest'
import ShiftAction from './shift-action'

export const ClockInAction = ClockIn.ClockInAction
export const ClockOutAction = ClockOut.ClockOutAction
export const EarlyClockInAction = EarlyClockIn.EarlyClockInAction
export const StartBreakAction = StartBreak.StartBreakAction
export const EndBreakAction = EndBreak.EndBreakAction

export { ShiftAction }

export const SHIFT_ACTIONS = {
  [EarlyClockIn.ACTION_NAME]: EarlyClockIn.EarlyClockInAction,
  [ClockIn.ACTION_NAME]: ClockIn.ClockInAction,
  [ClockOut.ACTION_NAME]: ClockOut.ClockOutAction,
  [StartBreak.ACTION_NAME]: StartBreak.StartBreakAction,
  [StartBreakRest.ACTION_NAME]: StartBreakRest.StartBreakRestAction,
  [StartBreakMeal.ACTION_NAME]: StartBreakMeal.StartBreakMealAction,
  [EndBreak.ACTION_NAME]: EndBreak.EndBreakAction,
  [DisapproveClockOutAction.ACTION_NAME]: DisapproveClockOutAction
}

export const SHIFT_ACTION_NAMES = {
  [EarlyClockIn.ACTION_NAME]: EarlyClockIn.ACTION_NAME,
  [ClockIn.ACTION_NAME]: ClockIn.ACTION_NAME,
  [ClockOut.ACTION_NAME]: ClockOut.ACTION_NAME,
  [StartBreak.ACTION_NAME]: StartBreak.ACTION_NAME,
  [StartBreakRest.ACTION_NAME]: StartBreakRest.ACTION_NAME,
  [StartBreakMeal.ACTION_NAME]: StartBreakMeal.ACTION_NAME,
  [EndBreak.ACTION_NAME]: EndBreak.ACTION_NAME,
  [DisapproveClockOutAction.ACTION_NAME]: DisapproveClockOutAction.ACTION_NAME
}
