import i18n from '@/i18n'

const I18N_METHODS = ['t', 'tc', 'te', 'd', 'n']

export const withI18n = (prefix = '') => (field) => {
  const pathPrefixed = (path) => {
    return [prefix, field.$form.name, field.name, path]
      .filter((path) => ![false, null, ''].includes(path))
      .join('.')
  }

  I18N_METHODS.forEach((method) => {
    field[`$${method}`] = (path, ...args) => {
      return i18n[method](pathPrefixed(path), ...args)
    }
  })

  return field
}

export default withI18n
