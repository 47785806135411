import ShiftAddress from './shift-address'

export class ShiftPosition {
  constructor(payload) {
    this.payload = payload || {}
    Object.defineProperties(this, {
      name: {
        enumerable: true,
        get() {
          return this.payload.name
        }
      },
      address: {
        enumerable: true,
        get() {
          const address = this.payload.account
            ? this.payload.account.address
            : null
          return ShiftAddress.fromPayload(address)
        }
      },
      siteName: {
        enumerable: true,
        get() {
          const siteName = this.payload.account
            ? this.payload.account.name
            : null
          return siteName
        }
      },
      alert: {
        enumerable: true,
        get() {
          return this.payload.shiftMemo
        }
      }
    })
  }

  static fromPayload(positionPayload) {
    if (!positionPayload) {
      return new ShiftPosition({
        scheduleMemo: '',
        name: '',
        account: {
          adress: {
            address: '',
            address_suite: '',
            city: '',
            country: '',
            state: '',
            zip: ''
          }
        }
      })
    }
    return new ShiftPosition(positionPayload)
  }
}

export default ShiftPosition
