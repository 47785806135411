<template>
  <KioskInteraction v-bind="$attrs" @timeout="setUserInteractionFromTraces">
    <KioskGuard :handler="guardUserActif">
      <template #default="props">
        <slot name="default" v-bind="props" />
      </template>
      <template #resolving="props">
        <slot name="resolving" v-bind="props" />
      </template>
      <template #rejected="props">
        <slot name="rejected" v-bind="props" />
      </template>
    </KioskGuard>
  </KioskInteraction>
</template>

<script>
import KioskInteraction from '@/components/KioskInteraction'
import KioskGuard from '@/components/KioskGuard'

export default {
  name: 'KioskGuardUserInteractionRequired',
  components: {
    KioskInteraction,
    KioskGuard
  },
  inheritAttrs: false,
  data() {
    return {
      isUserActif: true,
      isUserInactif: false
    }
  },
  computed: {
    guardUserActif() {
      const isUserActif = this.isUserActif
      return () => isUserActif
    }
  },
  methods: {
    setUserInteractionFromTraces(traces = []) {
      if (traces.length === 0) {
        this.isUserActif = false
        this.isUserInactif = true
        this.$emit('user-inactif')
      } else {
        this.isUserActif = true
        this.isUserInactif = false
        this.$emit('user-actif')
      }
    }
  }
}
</script>
