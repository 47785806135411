<script>
import words from 'lodash/words'
import property from 'lodash/property'
import capitalize from 'lodash/capitalize'
import md5 from 'crypto-js/md5'

export default {
  name: 'KioskAppHeap',
  props: {
    version: {
      type: [String, Number],
      required: true
    },
    heapOptions: {
      type: Object,
      default() {
        return {
          event: {
            Product: 'Kiosk',
            Version: this.version
          }
        }
      }
    }
  },
  mounted() {
    if (this.$heap) {
      this.$initHeap()
    }
  },
  methods: {
    $watchIdentity(handler) {
      const currentUserOf = property('kiosk.currentUser')
      const domainOf = property('kiosk.domain')
      const deviceUUIDOf = property('kiosk.deviceUUID')

      return this.$watch(
        () => ({
          currentUser: currentUserOf(this.$store.state),
          domain: domainOf(this.$store.state),
          deviceUUID: deviceUUIDOf(this.$store.state)
        }),
        (state) => handler(state),
        { immediate: true, deep: true }
      )
    },
    async $initHeap() {
      this.$addEventProperties(this.heapOptions.event)
      this.$watchIdentity((state) => {
        this.$heap.resetIdentity()
        this.$heap.identify(this.$pickIdentify(state))
        this.$addUserProperties(this.$pickUserProperties(state))
        this.$addEventProperties(this.$pickEventProperties(state))
      })
    },
    $formatProps(props) {
      return Object.entries(props).reduce((entries, [key, value]) => {
        const k = words(key)
          .map(capitalize)
          .join(' ')
        entries[k] = value
        return entries
      }, {})
    },
    $pickIdentify(state) {
      const { currentUser, domain, deviceUUID } = state

      if (currentUser) {
        return md5(`${domain}-${currentUser.user.id}`).toString()
      }

      if (domain) {
        return md5(`${domain}`).toString()
      }

      return md5(deviceUUID).toString()
    },
    $pickUserProperties(state) {
      return {
        Account: state.domain,
        'Device UUID': md5(state.deviceUUID).toString(),
        ...(this.heapOptions.user || {})
      }
    },
    $pickEventProperties(state) {
      return {
        'Portal Type': 'Kiosk',
        'Portal Language': this.$i18n.locale,
        ...(this.heapOptions.event || {})
      }
    },
    $addUserProperties(data) {
      this.$heap.addUserProperties(this.$formatProps(data))
    },
    $addEventProperties(data) {
      this.$heap.addEventProperties(this.$formatProps(data))
    }
  },
  render(h) {
    return this.$slots.default
  }
}
</script>
