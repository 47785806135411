import KioskError from './KioskError'
export const API_ERROR_NAME = 'API'

const fromSDK = (error) => {
  const { response, message } = error
  if (response && response.data) {
    return new KioskApiError(
      response.data.code,
      response.data.message,
      response.data.type,
      error
    )
  } else if (window.navigator.onLine === false) {
    return new KioskApiError('NETWORK_OFFLINE', message, null, error)
  } else {
    return new KioskApiError('NETWORK_ERROR', message, null, error)
  }
}

export class KioskApiError extends KioskError {
  constructor(code, message, type, inner) {
    super(code || message || 'API_ERROR_CODE_UNDEFINED', message, type, inner)
    this.name = API_ERROR_NAME
    this.inner = inner
  }

  static from(err) {
    if (err instanceof Error) {
      return fromSDK(err)
    } else {
      return new KioskApiError(err.code, err.message, err)
    }
  }
}

export default KioskApiError
