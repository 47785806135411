export const makePermissionGuard = ({ store }) => (to, from, next) => {
  const checkRoles = (roles = []) => {
    const permToMatch = roles.slice(0, roles.length - 1)
    const redirect = roles[roles.length - 1]
    const matchPermissions = store.getters['kiosk/matchPermissions'](
      permToMatch
    )

    if (to.name === redirect) {
      return false
    }

    return matchPermissions ? false : { name: redirect }
  }

  return to.matched
    .map(({ meta }) => meta.roles)
    .map((roles) => checkRoles(roles))
    .find((redirect) => redirect !== false)
}

export default makePermissionGuard
