import { assign, send } from 'xstate'
import { matchesProperty } from 'lodash'

const ShiftAction = {
  id: 'shift-action',
  initial: 'idle',
  context: {
    action: {
      name: null,
      response: false,
      error: false,
      running: false
    }
  },
  states: {
    idle: {
      entry: assign({
        action(context, event) {
          return {
            name: null,
            response: false,
            error: false,
            running: false
          }
        }
      })
    },
    before: {
      entry: assign({
        action(context, event) {
          return {
            ...event.action,
            response: false,
            error: false,
            running: false
          }
        }
      }),
      on: {
        'SHIFT_ACTION.CANCEL': {
          target: 'idle'
        },
        'SHIFT_ACTION.CONTINUE': {
          target: 'running'
        }
      }
    },
    running: {
      entry: assign({
        action(context) {
          return {
            ...context.action,
            running: true
          }
        }
      }),
      invoke: {
        src: 'executeShiftAction',
        onDone: {
          target: 'success',
          actions: assign({
            action(context, event) {
              return {
                ...context.action,
                response: event.data,
                running: false
              }
            }
          })
        },
        onError: {
          target: 'failure',
          actions: assign({
            action(context, event) {
              return {
                ...context.action,
                response: event.data,
                error: true,
                running: false
              }
            }
          })
        }
      }
    },
    success: {},
    failure: {
      after: {
        4000: 'idle'
      }
    }
  },
  on: {
    'SHIFT_ACTION.TRIGGER': {
      target: '.before'
    }
  }
}

const ShiftView = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        'SHIFT_VIEW.CHECK_SCHEDULE': [
          {
            target: 'schedule',
            cond: 'isSchedule'
          },
          { target: 'unschedule' }
        ]
      }
    },
    schedule: {
      initial: 'idle',
      states: {
        idle: {
          always: [
            {
              target: 'SCHEDULED',
              cond: {
                type: 'equal',
                key: 'shift.attendanceStatus',
                value: 'LATE'
              }
            },
            {
              target: 'SCHEDULED',
              cond: {
                type: 'equal',
                key: 'shift.attendanceStatus',
                value: 'SCHEDULED'
              }
            },
            {
              target: 'SCHEDULED',
              cond: {
                type: 'equal',
                key: 'shift.attendanceStatus',
                value: 'EARLY_CLOCK_OUT'
              }
            },
            {
              target: 'IN_PROGRESS',
              cond: {
                type: 'equal',
                key: 'shift.attendanceStatus',
                value: 'OVER_CLOCK'
              }
            },
            {
              target: 'IN_PROGRESS',
              cond: {
                type: 'equal',
                key: 'shift.attendanceStatus',
                value: 'IN_PROGRESS'
              }
            },
            {
              target: 'ON_BREAK',
              cond: {
                type: 'equal',
                key: 'shift.attendanceStatus',
                value: 'ON_BREAK'
              }
            }
          ]
        },
        SCHEDULED: {
          on: {
            'SHIFT_ACTION.CLOCK_IN': {
              actions: 'forwardToShiftAction'
            }
          }
        },
        IN_PROGRESS: {
          on: {
            'SHIFT_ACTION.CLOCK_OUT': {
              actions: 'forwardToShiftAction'
            },
            'SHIFT_ACTION.START_BREAK': {
              actions: 'forwardToShiftAction'
            },
            'SHIFT_ACTION.START_BREAK_REST': {
              actions: 'forwardToShiftAction'
            },
            'SHIFT_ACTION.START_BREAK_MEAL': {
              actions: 'forwardToShiftAction'
            }
          }
        },
        LATE: {
          on: {
            'SHIFT_ACTION.CLOCK_IN': {
              actions: 'forwardToShiftAction'
            }
          }
        },
        ON_BREAK: {
          on: {
            'SHIFT_ACTION.END_BREAK': {
              actions: 'forwardToShiftAction'
            },
            'SHIFT_ACTION.CLOCK_OUT': {
              actions: 'forwardToShiftAction'
            }
          }
        },

        EARLY_CLOCK_OUT: {
          on: {
            'SHIFT_ACTION.CLOCK_IN': {
              actions: 'forwardToShiftAction'
            }
          }
        },

        OVER_CLOCK: {
          on: {
            'SHIFT_ACTION.CLOCK_IN': {
              actions: 'forwardToShiftAction'
            },
            'SHIFT_ACTION.CLOCK_OUT': {
              actions: 'forwardToShiftAction'
            }
          }
        }
      }
    },
    unschedule: {
      type: 'final'
    }
  }
}

const ShiftControl = {
  id: 'shift-control',
  type: 'parallel',
  predictableActionArguments: true,
  context: {},
  states: {
    action: ShiftAction,
    views: ShiftView
  },
  on: {
    'SHIFT.UPDATE': {
      actions: ['assignScheduleInformationPayloadToCtx', 'checkShiftSchedule']
    }
  }
}

export const states = {
  ShiftControl
}

export const services = (vm) => ({
  executeShiftAction(context, event) {
    return Promise.resolve().then(() => {
      return context.action.trigger(event.payload)
    })
  },
  getShift() {
    const current = vm.$store.getters['kiosk/currentUser']
    return Promise.resolve(current)
  }
})

export const guards = (vm) => ({
  equal(context, event, { state, cond }) {
    return matchesProperty(cond.key, cond.value)(state.context)
  },
  includes(context, event, { state, cond }) {
    return cond.values.some((value) =>
      matchesProperty(cond.key, cond.value)(state.context)
    )
  },
  isSchedule(context) {
    const { shift } = context
    return [shift].some((value) => value)
  },
  didShiftEndDatePassed(context, event) {
    // console.log('didShiftEndDatePassed', { context, event })
    return false
  },
  didShiftStartDatePassed(context, event) {
    // console.log('didShiftStartDatePassed', { context, event })
    return false
  },
  didBreakManagementEnabled(context, event) {
    const { shift } = context
    return Object.keys(shift.breakCompliance || {}).length > 0
  }
})

export const actions = (vm) => ({
  forwardToShiftAction: send((context, event) => {
    const { payload, action } = event

    return { type: 'SHIFT_ACTION.TRIGGER', payload, action }
  }),
  assignScheduleInformationPayloadToCtx: assign((context, event) => {
    return {
      user: event.payload.user,
      shift: event.payload.shift,
      nextShift: event.payload.nextShift
    }
  }),
  checkShiftSchedule: send((context, event) => {
    return { type: 'SHIFT_VIEW.CHECK_SCHEDULE' }
  })
})
