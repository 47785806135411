import { createMachine, interpret } from 'xstate'
import { services, actions, guards, states } from './shift-control-machine'

export function useShiftControl(vm, options = {}) {
  const machineOptions = {
    services: services(vm),
    actions: actions(vm),
    guards: guards(vm),
    ...options.machine
  }

  const machine = createMachine(states.ShiftControl, machineOptions)
  const service = interpret(machine)

  return service
}

export default useShiftControl
