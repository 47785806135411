<script>
export default {
  name: 'KioskLocaleDropdown',
  functional: true,
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true
    },
    locales: {
      type: Array,
      required: true
    }
  },
  render(h, context) {
    const { value, locales, ...overflowProps } = context.props
    const localeValue = locales.find((locale) => locale.value === value)

    return h('VOverflowBtn', {
      props: {
        ...overflowProps,
        value: localeValue,
        items: context.props.locales
      },
      on: context.listeners
    })
  }
}
</script>
