import Vue from 'vue'
import get from 'lodash/get'

export class FormState {
  constructor(initialData = {}, initialState = {}) {
    const $formState = Vue.observable({
      data: initialData,
      state: {
        error: null,
        submitted: false,
        loading: false,
        ...initialState
      }
    })

    Object.defineProperties(this, {
      $formState: {
        enumerable: false,
        value: $formState
      },
      data: {
        enumerable: true,
        get() {
          return $formState.data
        },
        set(value) {
          $formState.data = value
        }
      },
      error: {
        enumerable: true,
        get() {
          return $formState.state.error
        }
      },
      loading: {
        enumerable: true,
        get() {
          return $formState.state.loading
        }
      },
      submitted: {
        enumerable: true,
        get() {
          return $formState.state.submitted
        }
      }
    })
  }

  setError(err) {
    this.$formState.state.error = err
  }

  setLoading(loading) {
    this.$formState.state.loading = loading
  }

  setSubmitted(submitted) {
    this.$formState.state.submitted = submitted
  }

  get(path) {
    return get(this.data, path)
  }

  set(path, value) {
    Vue.set(this.data, path, value)
  }
}

export default FormState
