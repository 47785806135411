<script>
const seconds = (time) => time * 1000

export default {
  name: 'KioskInteraction',
  props: {
    interval: {
      type: Number,
      default: seconds(30)
    },
    resetAfterTimeout: {
      type: Boolean,
      default: true
    },
    target: {
      type: [Function, Document, HTMLElement],
      default() {
        return document
      }
    },
    events: {
      type: Array,
      default() {
        return ['click', 'mousemove', 'keydown', 'touch']
      }
    },
    onTimeout: {
      type: Function,
      default() {}
    },
    onInteract: {
      type: Function,
      default: (eventName, event) => {
        return [eventName, event]
      }
    },
    spy: {
      type: Function,
      default({ interact }, { el, events }) {
        events.forEach((eventName) => {
          const makeInteract = this.$interact.bind(this, eventName)
          el.addEventListener(eventName, makeInteract)
        })
      }
    },
    tag: {
      type: String,
      default: 'div'
    }
  },
  data() {
    return {
      traces: [],
      el: typeof this.target === 'function' ? this.target() : this.target
    }
  },
  mounted() {
    this.$setInterval()
    this.spy({ interact: this.$interact.bind(this) }, this)
  },
  destroyed() {
    this.$clearInterval()
  },
  methods: {
    $setInterval() {
      this.$interval = setInterval(this.$timeoutAndReset, this.interval)
    },

    $clearInterval() {
      clearInterval(this.$interval)
    },

    $timeoutAndReset() {
      this.onTimeout(this.traces)
      this.$emit('timeout', this.traces)
      if (this.resetAfterTimeout) {
        this.traces = []
      }
    },

    $interact(eventName, event) {
      let trace = this.onInteract(eventName, event)

      if (trace === false) {
        return false
      }

      if (trace === undefined) {
        this.traces.push([eventName, event])
        return
      }

      this.traces.push(trace)
    }
  },
  render(h) {
    if (this.$slots.default && this.$slots.default.length === 1) {
      return this.$slots.default[0]
    }

    if (this.$slots.default && this.$slots.default.length > 1) {
      return h(this.tag, null, this.$slots.default)
    }

    if (this.$scopedSlots.default) {
      return this.$scopedSlots.default({
        traces: this.traces,
        interact: this.$interact.bind(this)
      })
    }

    return null
  }
}
</script>
