import ShiftAction from './shift-action'

export const ACTION_NAME = 'END_BREAK'
export class EndBreakAction extends ShiftAction {
  constructor(trigger, meta = {}) {
    meta.icon = 'img/icons/stopbreak.svg'
    super(ACTION_NAME, trigger, meta)
  }
}

export default EndBreakAction
