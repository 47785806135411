export class ShiftAction {
  constructor(name, trigger, meta = {}) {
    this.name = name
    this.meta = meta

    this.trigger = (payload) => {
      trigger(payload, this)
    }

    this.icon = this.meta.icon
  }

  is(action) {
    if (typeof action === 'string') {
      return this.name === action
    }

    return Object.is(this, action)
  }
}

export default ShiftAction
