import Vue from 'vue'

export const withControlAction = (Action, options = {}) => {
  const $actionState = Vue.observable({
    loading: false,
    submitted: false,
    error: false
  })

  return class extends Action {
    constructor(...args) {
      super(...args)
      Object.defineProperties(this, {
        state: {
          enumerable: true,
          get() {
            return $actionState
          }
        }
      })
    }

    loading(loadingValue = true) {
      $actionState.loading = loadingValue
    }

    submitted(submittedValue = true) {
      $actionState.submitted = submittedValue
    }

    error(err) {
      $actionState.error = err
    }
  }
}

export default withControlAction
