export const enumerableProps = (
  target,
  props = [],
  enumerable = true,
  omits = ['constructor']
) => {
  props
    .filter((prop) => omits.includes(prop) === false)
    .forEach((prop) => {
      Object.defineProperty(target, prop, {
        ...Object.getOwnPropertyDescriptor(target, prop),
        enumerable
      })
    })
}

export default enumerableProps
