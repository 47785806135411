import ShiftBreakSessionTreahold from './shift-break-session-treahold'
import moment from 'moment'

export { ShiftBreakSessionTreahold }

export class ShiftBreakCompliance {
  constructor(payload, options = {}) {
    this.payload = payload || {}
    this.options = {
      breakInMinutes: 0,
      now: new Date(),
      ...options
    }

    Object.defineProperties(this, {
      clockIn: {
        enumerable: true,
        get() {
          if (this.payload.computedSessions) {
            return this.payload.computedSessions.clockIn.expected
          }
          return null
        }
      },
      breakComplianceItems: {
        enumerable: true,
        get() {
          if (this.payload) {
            return this.payload.breakComplianceItems
          }
          return []
        }
      },
      summary: {
        enumerable: true,
        get() {
          if (this.payload) {
            return this.payload.summary
          }
          return []
        }
      },
      breakRuleConditions: {
        enumerable: true,
        get() {
          if (this.payload) {
            return this.sortBreakRuleConditionsBy(this.sorts.ASC) || []
          }
          return []
        }
      },
      computedSessions: {
        enumerable: true,
        get() {
          if (this.payload) {
            return this.payload.computedSessions
          }
          return []
        }
      }
    })
  }

  get sorts() {
    return {
      ASC: (prev, curr) => prev.offsetStart - curr.offsetStart,
      DESC: (prev, curr) => curr.offsetStart - prev.offsetStart
    }
  }

  timeValue(time) {
    return moment(this.clockIn)
      .add(time, 'm')
      .valueOf()
  }

  sortBreakRuleConditionsBy(predicate) {
    return [].concat(this.payload.breakRuleConditions).sort(predicate)
  }

  findNextBreakComplianceItems() {
    if (this.breakRuleConditions.length === 0) {
      return
    }
    const addTime = (item) => {
      if (item.offsetEnd !== 0) {
        return { time: this.timeValue(item.offsetEnd), ...item }
      }
      return { time: this.timeValue(item.offsetStart), ...item }
    }
    const byTimeAsc = (prev, curr) => prev.time - curr.time
    const nextBreak = (item) => moment().valueOf() < item.time
    const item = this.breakRuleConditions
      .map(addTime)
      .sort(byTimeAsc)
      .find(nextBreak)
    if (!item) {
      return
    }
    return item
  }

  isRangeBreakCondition(breakCondition) {
    return breakCondition.offsetEnd !== 0
  }

  hasBreaks() {
    if (!this.payload) {
      return this.options.breakInMinutes > 0
    }

    if ('breakManagementEnableFeature' in this.payload) {
      return this.payload.breakManagementEnableFeature === true
    }

    return this.payload.summary !== null
  }

  static fromPayload(payload, options) {
    return new ShiftBreakCompliance(payload, options)
  }
}

export default ShiftBreakCompliance
