<template>
  <VRow>
    <VCol>
      <VImg v-bind="logoProps" />
    </VCol>
  </VRow>
</template>

<script>
import {
  SETTING_COMPANY_DEFAULT_LOGO,
  SETTING_TRACKTIK_DEFAULT_LOGO
} from '@/lib/kiosk'

export default {
  name: 'KioskLogoToolbarItem',
  props: {
    tracktikLogo: {
      type: String,
      default: SETTING_TRACKTIK_DEFAULT_LOGO
    },
    companyLogo: {
      type: String,
      default: SETTING_COMPANY_DEFAULT_LOGO
    },
    logo: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    logoProps() {
      return {
        src: [this.companyLogo, this.tracktikLogo].find((logo) => logo),
        contain: true,
        ...this.$attrs
      }
    }
  }
}
</script>
