<script>
import KioskInputBtnGroup from './KioskInputBtnGroup'

export default {
  name: 'KioskLocaleBtnGroup',
  functional: true,
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true
    },
    locales: {
      type: Array,
      required: true
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.locales[this.values].value)
    }
  },
  render(h, context) {
    return h(KioskInputBtnGroup, {
      props: {
        ...context.props,
        items: context.props.locales,
        itemAttrs: context.props.locales.map((locale) => {
          return {
            'data-heap-id': `lang_${locale.value}`
          }
        }),
        mandatory: true
      },
      on: context.listeners
    })
  }
}
</script>
