<template>
  <Component :is="tag" v-bind="$attrs">
    <slot :format="formats" :time="time" :tz="tz" :value="valueTz.toString()">
      <template v-if="time.length > 0">
        {{ time[0] }}
      </template>
    </slot>
  </Component>
</template>
<script>
import moment from 'moment-timezone'

const defaultProps = {
  tag: () => 'span',
  format: () => 'LT',
  tz: () => {
    return moment.tz.guess(true)
  },
  locale: (vm) => {
    return vm.$i18n.locale
  },
  value: (vm) => {
    return new Date()
  }
}

export default {
  name: 'KioskClock',
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: defaultProps.tag()
    },
    format: {
      type: [String, Array],
      default: defaultProps.format()
    },
    tz: {
      type: [String],
      default() {
        return defaultProps.tz(this)
      }
    },
    locale: {
      type: String,
      default() {
        return defaultProps.locale(this)
      }
    },
    value: {
      type: [String, Date],
      default: defaultProps.value()
    }
  },
  computed: {
    valueTz() {
      const tz = moment.tz(this.value, this.tz).clone()
      return tz
    },
    formats() {
      const arr = Array.isArray(this.format) ? this.format : [this.format]
      const finale = arr.filter((item) => ![null, undefined].includes(item))

      if (finale.length === 0) {
        return [defaultProps.format(this)]
      }

      return finale
    },
    localeOrDefault() {
      return [undefined, null].includes(this.locale)
        ? defaultProps.locale(this)
        : this.locale
    },
    time() {
      if (!moment.isMoment(this.valueTz) || !this.valueTz.isValid()) {
        this.$log.warn(
          `valuetTz("${this.valueTz}") is not a valid moment object`
        )
        return []
      }

      const valueTzLocale = this.valueTz.locale(this.localeOrDefault)
      if (!moment.isMoment(valueTzLocale) || !valueTzLocale.isValid()) {
        this.$log.warn(
          `valueTzLocale("${valueTzLocale}", "${this.localeOrDefault}") is not a valid moment object`
        )
        return []
      }

      return this.formats.map((targetFormat) =>
        valueTzLocale.format(targetFormat)
      )
    }
  }
}
</script>
