import KioskApiError from '@/errors/KioskApiError'
import * as actionTypes from './action-types'
import * as mutationTypes from './mutation-types'
import { adminService, kioskService } from '../../../plugins/sdk'

export const normalizeApiResponse = (context, response) => {
  if ('meta' in response && 'data' in response) {
    return {
      response,
      result: response.data
    }
  }

  return {
    response,
    result: response
  }
}

export const checkPermissionOrReset = (context, perms, resetArgs) => {
  if (!Array.isArray(perms)) {
    return
  }

  if (context.getters.matchPermissions(perms)) {
    return
  }

  context.commit(mutationTypes.RESET_USER, resetArgs)

  throw new KioskApiError(
    'KIOSK_VERIFY_EMPLOYEE_PERMISSIONS_BRV_0000012',
    'forbidden'
  )
}

export const resetApplicationOnError = (errorKeys) => {
  return async (context, error) => {
    if (!errorKeys.includes(error.code)) {
      return
    }

    context.commit(mutationTypes.UNINSTALL)
    await context.dispatch(actionTypes.WINDOW_RELOAD, true)
  }
}

const resetOnSecurityError = resetApplicationOnError([
  'KIOSK_INSTALLATION_BRV_000006'
])

const getKioskCredentials = ({ auth, deviceUUID }) => {
  const appToken = auth && auth.appToken
  const deviceLocalIdentifier = deviceUUID

  return {
    ...(deviceLocalIdentifier && { deviceLocalIdentifier }),
    ...(appToken && { appToken })
  }
}

export const isResetNeeded = (reset, defaultResetValue = true) => {
  return typeof reset === 'undefined' ? defaultResetValue : reset
}

export default {
  async [actionTypes.WINDOW_RELOAD](context, forceReload) {
    window.location.reload(forceReload)
  },
  async [actionTypes.FETCH](context, { reset, action }) {
    const needReset = isResetNeeded(reset)

    try {
      context.commit(mutationTypes.SET_ERROR, null)
      return normalizeApiResponse(context, await action())
    } catch (error) {
      const apiError = KioskApiError.from(error)

      context.commit(mutationTypes.SET_ERROR, apiError)
      if (needReset) {
        await resetOnSecurityError(context, apiError)
      }

      throw apiError
    }
  },

  async [actionTypes.INSTALL](
    { dispatch, commit, state },
    { domain, installationCode }
  ) {
    commit(mutationTypes.SET_DOMAIN, domain)

    const action = () => {
      const api = kioskService(state, { timeout: 10000 })

      return api.install({
        installCode: installationCode,
        deviceAppName: state.deviceUUID,
        deviceLocalIdentifier: state.deviceUUID
      })
    }

    const { result } = await dispatch(actionTypes.FETCH, {
      reset: false,
      action
    })

    commit(mutationTypes.INSTALL, result)

    return result
  },

  async [actionTypes.UNINSTALL]({ dispatch, commit, state, getters }) {
    const { licenseId } = getters

    const action = () => {
      const api = adminService(state)

      return api.appLicenses.reset(licenseId)
    }

    const { result } = await dispatch(actionTypes.FETCH, { action })

    commit(mutationTypes.UNINSTALL)
    return result
  },

  async [actionTypes.VERIFY_EMPLOYEE_CUSTOM_ID](
    { dispatch, commit, state },
    { customId }
  ) {
    const action = () => {
      const api = kioskService(state)

      return api.verifyEmployeeCustomId({
        ...getKioskCredentials(state),
        customId
      })
    }

    const { result } = await dispatch(actionTypes.FETCH, {
      action
    })

    commit(mutationTypes.SET_CURRENT_USER, {
      authMode: actionTypes.VERIFY_EMPLOYEE_CUSTOM_ID,
      ...result
    })

    return result
  },

  async [actionTypes.VERIFY_EMPLOYEE_CREDENTIALSS](
    context,
    { username, password, perms }
  ) {
    const action = () => {
      const api = kioskService(context.state)

      return api.verifyEmployeePassword({
        ...getKioskCredentials(context.state),
        username,
        password
      })
    }

    const { result } = await context.dispatch(actionTypes.FETCH, {
      action
    })

    context.commit(mutationTypes.SET_CURRENT_USER, {
      authMode: actionTypes.VERIFY_EMPLOYEE_CREDENTIALSS,
      ...result
    })

    checkPermissionOrReset(context, perms, {
      authMode: actionTypes.VERIFY_EMPLOYEE_CREDENTIALSS,
      ...result
    })

    return result
  },

  async [actionTypes.CLOCK_IN]({ dispatch, commit, state, getters }) {
    const { currentUser, currentUserId: employeeId } = getters

    const action = () => {
      const api = kioskService(state)

      return api.clockIn({
        ...getKioskCredentials(state),
        employeeId
      })
    }

    const { result } = await dispatch(actionTypes.FETCH, { action })

    commit(mutationTypes.SET_CURRENT_USER, {
      ...currentUser,
      ...result
    })

    return result
  },

  async [actionTypes.CLOCK_OUT]({ dispatch, state, getters }) {
    const employeeId = getters.currentUserId
    const { id: shiftId } = state.currentUser.shift || {}

    const action = () => {
      const api = kioskService(state)

      return api.clockOut({
        ...getKioskCredentials(state),
        employeeId,
        shiftId
      })
    }

    const { result } = await dispatch(actionTypes.FETCH, { action })
    return result
  },

  async [actionTypes.START_BREAK]({ dispatch, state, getters }, payload = {}) {
    const employeeId = getters.currentUserId

    const action = () => {
      const api = kioskService(state)

      return api.breakStart({
        ...payload,
        ...getKioskCredentials(state),
        employeeId
      })
    }

    const { result } = await dispatch(actionTypes.FETCH, { action })
    return result
  },

  async [actionTypes.START_BREAK_MEAL](
    { dispatch, state, getters },
    payload = {}
  ) {
    const employeeId = getters.currentUserId

    const action = () => {
      const api = kioskService(state)

      return api.breakStart({
        ...payload,
        breakType: 'MEAL',
        ...getKioskCredentials(state),
        employeeId
      })
    }

    const { result } = await dispatch(actionTypes.FETCH, {
      action
    })

    return result
  },

  async [actionTypes.START_BREAK_REST](
    { dispatch, state, getters },
    payload = {}
  ) {
    const employeeId = getters.currentUserId

    const action = () => {
      const api = kioskService(state)

      return api.breakStart({
        ...payload,
        breakType: 'REST',
        ...getKioskCredentials(state),
        employeeId
      })
    }

    const { result } = await dispatch(actionTypes.FETCH, { action })
    return result
  },

  async [actionTypes.END_BREAK]({ dispatch, state, getters }) {
    const employeeId = getters.currentUserId

    const action = () => {
      const api = kioskService(state)

      return api.breakEnd({
        ...getKioskCredentials(state),
        employeeId
      })
    }

    const { result } = await dispatch(actionTypes.FETCH, { action })
    return result
  },

  async [actionTypes.FETCH_SCHEDULE_INFORMATION]({
    dispatch,
    commit,
    state,
    getters
  }) {
    const { currentUser, currentUserId: employeeId } = getters

    const action = () => {
      const api = kioskService(state)

      return api.scheduleInformation({
        ...getKioskCredentials(state),
        employeeId
      })
    }

    const { result } = await dispatch(actionTypes.FETCH, { action })

    commit(mutationTypes.SET_CURRENT_USER, {
      ...currentUser,
      ...result
    })

    return result
  },

  async [actionTypes.DISAPPROVE_SHIFT](
    { dispatch, commit, state, getters },
    payload = {}
  ) {
    const { currentUserId: employeeId } = getters

    const action = () => {
      const api = kioskService(state)

      return api.connector.httpClientInstance.post(
        '/apps/kiosk/shift-disapproval',
        {
          ...getKioskCredentials(state),
          shiftId: getters.currentUser.shift.id,
          employeeId,
          comment: '[Kiosk] Disapproved',
          ...payload
        }
      )
    }

    const { result } = await dispatch(actionTypes.FETCH, {
      action
    })
    return result
  },

  async [actionTypes.BACK](context) {
    return context.commit(mutationTypes.RESET_USER)
  },

  async [actionTypes.SAVE_SETTING](context, fields) {
    return context.commit(mutationTypes.SET_SETTING, fields)
  },

  async [actionTypes.LOGOUT](context) {
    return context.commit(mutationTypes.RESET_USER)
  }
}
