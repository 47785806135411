<template>
  <KioskToolbar app flat :height="'100px'" color="transparent" v-bind="$attrs">
    <VToolbarItems>
      <Component
        :is="item('logo').is"
        v-if="item('logo').is"
        v-bind="item('logo').props"
      />
      <Component
        :is="item('helpDesk').is"
        v-if="item('helpDesk').is && item('helpDesk').props.phoneNumber"
        v-bind="item('helpDesk').props"
      />
    </VToolbarItems>
    <VSpacer />
    <VToolbarItems>
      <VRow align="center" class="flex-nowrap pa-3 px-0">
        <VCol cols="auto">
          <Component
            :is="item('locale').is"
            v-if="item('locale').is"
            v-model="$i18n.locale"
            v-bind="item('locale').props"
          />
        </VCol>
        <VCol cols="auto">
          <Component
            :is="item('setting').is"
            v-if="item('setting').is"
            :icon="item('setting').icon"
            :large="item('setting').icon"
            :to="item('setting').props.to"
          >
            <KioskIconSvg
              v-if="
                item('setting').props.icon &&
                  item('setting').props.icon.filepath
              "
              :filepath="item('setting').props.icon.filepath"
              :fill="item('setting').props.icon.color"
              :size="item('setting').props.icon.size"
            />
          </Component>
          <KioskClockI10n v-if="item('clock').is" v-bind="item('clock').props">
            <template #default="{ time: [hours, daytime] }">
              <h3 class="display-2 text-right" v-text="hours" />
              <p
                style="width: 100%"
                class="subtitle-1 text-lowercase text-right ma-0"
                v-text="daytime"
              />
            </template>
          </KioskClockI10n>
        </VCol>
      </VRow>
    </VToolbarItems>
  </KioskToolbar>
</template>

<script>
import KioskToolbar from '@/components/KioskToolbar'
import KioskSettingLocale from '../setting/KioskSettingLocale'
import KioskHelpDeskToolbarItem from './KioskHelpDeskToolbarItem'
import KioskLogoToolbarItem from './KioskLogoToolbarItem'
import KioskIconSvg from '@/components/KioskIconSvg'
import KioskClockI10n from '@/components/KioskClockI10n'

const getLogoItemDefault = (vm) => {
  const setting = vm.$store.getters['kiosk/setting']
  const { logoCompany: companyLogo } = setting

  return {
    is: KioskLogoToolbarItem,
    props: {
      companyLogo,
      width: 240,
      height: 80
    }
  }
}

const getHelpDeskItemDefault = (vm) => {
  return {
    is: KioskHelpDeskToolbarItem,
    props: vm.$store.getters['kiosk/setting']
  }
}

const getSettingItemDefault = (vm) => {
  return {
    is: 'VBtn',
    icon: true,
    large: true,
    props: {
      icon: {
        filepath: '/img/icons/setting.svg',
        color: '#2c59cd',
        size: '30'
      },
      to: {
        name: 'admin.index'
      }
    }
  }
}

const getClockItemDefault = (vm) => {
  return {
    is: false,
    props: {
      tag: 'VRow',
      locale: vm.$i18n.locale,
      locales: {
        en: ['hh:mm'],
        fr: ['HH:mm', 'A'],
        es: ['HH:mm', 'A']
      },
      row: true,
      wrap: true,
      alignCenter: true,
      justifyEnd: true,
      fillHeight: true
    }
  }
}

const getLocaleItemDefault = (vm) => {
  return {
    is: KioskSettingLocale,
    props: {
      type: 'btn-group',
      right: true,
      flat: true,
      backgroundColor: 'transparent',
      sliderColor: 'error'
    }
  }
}

export default {
  name: 'KioskBasicToolbar',
  components: {
    KioskToolbar,
    KioskClockI10n,
    KioskIconSvg
  },
  inheritAttrs: false,
  props: {
    items: {
      type: Object,
      default() {
        return {}
      }
    },
    defaultItems: {
      type: Object,
      default() {
        return {
          helpDesk: getHelpDeskItemDefault(this),
          setting: getSettingItemDefault(this),
          logo: getLogoItemDefault(this),
          clock: getClockItemDefault(this),
          locale: getLocaleItemDefault(this)
        }
      }
    }
  },
  computed: {
    itemsOrDefault() {
      return Object.entries(this.items)
        .map(([key, value]) => [key, value, this.defaultItems[key]])
        .map(([key, value, defaultValue]) => {
          if (value === true) {
            return [key, defaultValue]
          }

          return [key, value, defaultValue]
        })
        .reduce((items, [key, value, defaultValue]) => {
          items[key] = value
          return items
        }, {})
    },
    item() {
      return (itemKey) => {
        const isFalsy = [undefined, null, false].includes(
          this.itemsOrDefault[itemKey] || false
        )

        if (isFalsy) {
          return {
            is: false,
            props: {}
          }
        }

        return this.itemsOrDefault[itemKey]
      }
    }
  }
}
</script>
