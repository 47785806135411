<script>
import KioskLocaleSelect from '@/components/KioskLocaleSelect'
import KioskLocaleBtnGroup from '@/components/KioskLocaleBtnGroup'
import KioskLocaleDropdown from '@/components/KioskLocaleDropdown'
import KioskLocaleTabs from '@/components/KioskLocaleTabs'

const TYPE_BTN_GROUP = 'btn-group'
const TYPE_SELECT = 'select'
const TYPE_DROPDOWN = 'dropdown'
const TYPE_TABS = 'tabs'

export default {
  name: 'KioskSettingLocale',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default() {
        return this.$i18n.locale
      }
    },
    type: {
      type: String,
      default: TYPE_BTN_GROUP
    },
    locales: {
      type: Array,
      default() {
        const setting = this.$store.getters['kiosk/setting']
        return setting.langs
      }
    },
    text: {
      type: Function,
      default(langs) {
        return langs.map((locale) => ({
          text: this.$t(`app.kiosk.langs.${locale}.btnText`),
          value: locale
        }))
      }
    },
    sync: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      [TYPE_BTN_GROUP]: KioskLocaleBtnGroup,
      [TYPE_DROPDOWN]: KioskLocaleDropdown,
      [TYPE_TABS]: KioskLocaleTabs,
      [TYPE_SELECT]: KioskLocaleSelect
    }
  },
  render(h) {
    return h(this[this.type], {
      props: {
        value: this.value,
        locales: this.text(this.locales)
      },
      attrs: this.$attrs,
      on: {
        input: (newLocale) => {
          if (this.sync) {
            this.$i18n.locale = newLocale
          }
        }
      }
    })
  }
}
</script>
