import ShiftPosition from './shift-position'
import ShiftBreakCompliance from './shift-break-compliance'

const chooseIconOr = (shift, iconFallback) => {
  return iconFallback
}

export class ShiftSummary {
  constructor(payload) {
    Object.assign(this, { shift: payload || {} })

    Object.defineProperties(this, {
      isEmpty: {
        enumerable: true,
        get() {
          if ([undefined, null].includes(this.shift)) {
            return true
          }

          return Object.keys(this.shift).length === 0
        }
      },
      startDateTime: {
        enumerable: true,
        get() {
          return this.shift.startDateTime
        }
      },
      endDateTime: {
        enumerable: true,
        get() {
          return this.shift.endDateTime
        }
      },
      breakInMinutes: {
        enumerable: true,
        get() {
          return this.shift.breakInMinutes
        }
      },
      icon: {
        enumerable: true,
        get() {
          return chooseIconOr(this.shift, 'scheduled')
        }
      },
      alert: {
        enumerable: true,
        get() {
          return this.position.alert
        }
      },
      position: {
        enumerable: true,
        get() {
          return ShiftPosition.fromPayload(
            this.shift ? this.shift.position : null
          )
        }
      },
      siteName: {
        enumerable: true,
        get() {
          return this.position.siteName
        }
      },
      startGracePeriodInMinutes: {
        enumerable: true,
        get() {
          return this.shift.startGracePeriodInMinutes
        }
      },
      breakCompliance: {
        enumerable: true,
        get() {
          return ShiftBreakCompliance.fromPayload(this.shift.breakCompliance, {
            breakInMinutes: this.shift.breakInMinutes
          })
        }
      }
    })
  }

  static fromPayload(shiftPayload) {
    return new ShiftSummary(shiftPayload)
  }
}

export default ShiftSummary
