import mergeWith from 'lodash/mergeWith'

const flatten = (list) =>
  list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), [])

const concatOnArray = (objValue, srcValue) => {
  if (Array.isArray(objValue)) {
    return objValue.concat(srcValue)
  }
}

export const withVuetify = (options = {}) => (validationParams, field, vm) => {
  const showErrorOn = options.showErrorOn || (({ $dirty }) => $dirty)

  const errorMessages = () => {
    const paramValue = field.$v[validationParams.name]
    if (!paramValue && showErrorOn(field.$v)) {
      return [field.$t(validationParams.name, validationParams)]
    }
    return []
  }

  return {
    [options.errorKey]: errorMessages()
  }
}

const normalizeWiths = (options) => {
  return (options.withs || [[withVuetify, options]]).map(
    ([withInit, options]) => {
      return withInit(options)
    }
  )
}

export const withVuelidate = (options = {}) => (field, vm) => {
  const withs = normalizeWiths(options)

  return field.$v
    .$flattenParams()
    .map((validationParams) => {
      return withs.map((withFn) => withFn(validationParams, field, vm))
    })
    .reduce((props, value) => props.concat(flatten(value)), [])
    .reduce((props, validationProps) => {
      return mergeWith(props, validationProps, concatOnArray)
    }, {})
}

export default withVuelidate
