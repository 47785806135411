import * as GuardComponents from './components'
import applyRouterGuards from './router'

export const components = GuardComponents
export { applyRouterGuards, GuardComponents }

export default {
  applyRouterGuards,
  ...GuardComponents
}
