import { register } from 'register-service-worker'
import consola from 'consola'

if (
  process.env.NODE_ENV === 'production' &&
  process.env.VUE_APP_PWA === 'true'
) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      consola.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered(registration) {
      consola.log('Service worker has been registered.')
      const updateInterval = parseInt(
        process.env.VUE_APP_PWA_UPDATE_INTERVAL_MS
      )
      if (updateInterval) {
        setInterval(() => {
          registration.update().catch(consola.error)
        }, updateInterval)
      }
    },
    cached() {
      consola.log('Content has been cached for offline use.')
    },
    updatefound() {
      consola.log('New content is downloading.')
    },
    updated(registration) {
      consola.log('New content is available; please refresh.')
      if (registration.active) {
        registration.active.addEventListener('statechange', (e) => {
          window.location.reload(true)
        })
      }
    },
    offline() {
      consola.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      consola.error('Error during service worker registration:', error)
    }
  })
}

if (process.env.VUE_APP_PWA !== 'true') {
  const hasServiceWorker = 'serviceWorker' in navigator

  if (navigator && hasServiceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((reg) => reg.unregister())
    })
  }
}
