<template>
  <VRow>
    <VCol cols="12">
      <VBtnToggle
        v-model="selected"
        tile
        group
        :multiple="multiple"
        :mandatory="mandatory"
      >
        <template>
          <VBtn
            v-for="(item, index) in items"
            :key="item.value"
            v-bind="itemAttrs[index]"
          >
            {{ item.text }}
          </VBtn>
        </template>
      </VBtnToggle>
    </VCol>
  </VRow>
</template>

<script>
const findIndexOfSelectedItems = (items, values) => {
  const findIndex = (value) => items.findIndex((item) => item.value === value)
  return values.map(findIndex).filter((index) => {
    return index >= 0
  })
}

const findValueOfSelectedItems = (items, index) => {
  const indexExist = (index) => index in items
  return index.filter(indexExist).map((index) => items[index].value)
}

export default {
  name: 'KioskInputBtnGroup',
  inheritAttrs: false,
  props: {
    value: {
      type: [Array, String, Number, Object],
      default() {
        return []
      }
    },
    label: {
      type: String,
      default: undefined
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    itemAttrs: {
      type: Array,
      default() {
        return []
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    mandatory: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {},
  watch: {
    selected(changed) {
      this.$emit('input', this.findValueOfSelectedItems())
    },
    value(changed) {
      this.selected = this.findIndexOfSelectedItems()
    }
  },
  mounted() {
    this.selected = this.findIndexOfSelectedItems()
  },
  methods: {
    findValueOfSelectedItems() {
      if (this.multiple) {
        return findValueOfSelectedItems(this.items, this.selected)
      }

      return findValueOfSelectedItems(this.items, [this.selected]).shift()
    },
    findIndexOfSelectedItems() {
      if (this.multiple) {
        return findIndexOfSelectedItems(this.items, this.value)
      }

      return findIndexOfSelectedItems(this.items, [this.value]).shift()
    }
  }
}
</script>
