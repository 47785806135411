import KioskError from './KioskError'
import KioskApiError from './KioskApiError'

export const FORM_ERROR_NAME = 'FORM'

export class KioskFormError extends KioskError {
  constructor(code, message, inners = []) {
    super(code, message)
    this.name = FORM_ERROR_NAME
    this.inners = Array.isArray(inners) ? inners : [inners]
  }

  static from(err) {
    if (err instanceof String) {
      return new KioskFormError(null, err)
    } else if (err instanceof KioskApiError) {
      return new KioskFormError(err.code, err.message, err)
    } else if (err instanceof Error) {
      return new KioskFormError(err.code, err.message || err.toString(), err)
    } else {
      return new KioskFormError(err.code, err.message, [err])
    }
  }
}

export default KioskFormError
