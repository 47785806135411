import consola from 'consola'
import lowerCase from 'lodash/lowerCase'
import router from '@/router'
import NewRelic from './new-relic'
import Ga from './ga'
import Sentry from './sentry'
import Heap from './heap'

export const vendors = {
  NewRelic,
  Ga,
  Sentry,
  Heap
}

export const vendorsOpts = {
  newrelic: {
    licenseKey: process.env.VUE_APP_NEWRELIC_LICENSE_KEY,
    applicationId: process.env.VUE_APP_NEWRELIC_APPLICATION_ID
  },
  heap: {
    appId: process.env.VUE_APP_HEAP_APP_ID,
    secureCookie: true,
    disableTextCapture: true,
    forceSSL: true
  },
  ga: {
    apiKey: process.env.VUE_APP_GA_API_KEY,
    debug: {
      enabled: process.env.VUE_APP_GA_DEBUG === 'true',
      sendHitTask: true
    },
    autoTracking: {
      exception: true,
      screenview: true
    },
    router
  },
  sentry: {
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: [
      process.env.VUE_APP_SENTRY_RELEASE,
      process.env.VUE_APP_PKG_VERSION
    ]
  }
}

export function install(Vue, options = vendorsOpts) {
  const tryInstallOneVendor = (Vendor, options) => {
    try {
      Vue.use(Vendor, options)
    } catch (err) {
      consola.error(err)
    }
  }

  Object.keys(vendors).forEach((vendorName) => {
    const optionKey = lowerCase(vendorName).replace(' ', '')
    const Vendor = vendors[vendorName]
    const vendorOptions = options[optionKey]
    tryInstallOneVendor(Vendor, vendorOptions)
  })
}

export default {
  install,
  vendors
}
