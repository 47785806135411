<template>
  <KioskApp>
    <template #default="kiosk">
      <Component :is="appLayout" :app="kiosk">
        <template #default="layout">
          <RouterView
            v-bind="layout.contentProps"
            v-on="layout.contentListeners"
          />
        </template>
      </Component>
    </template>
  </KioskApp>
</template>

<script>
import KioskApp from '@/components/KioskApp'
import DialogLayout from './dialog'
import PageLayout from './page'

export default {
  name: 'KioskLayout',
  components: {
    KioskApp
  },
  props: {
    layouts: {
      type: Object,
      default() {
        return {
          Page: PageLayout,
          Dialog: DialogLayout
        }
      }
    },
    layout: {
      type: String,
      default: 'Page'
    }
  },
  data() {
    return {
      appLayoutName: this.layout
    }
  },
  computed: {
    appLayout() {
      return this.layouts[this.appLayoutName] || this.layouts[this.layout]
    }
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler: 'applyRouteMetaLayout'
    }
  },
  methods: {
    applyRouteMetaLayout() {
      const layout = [...this.$route.matched]
        .reverse()
        .filter(({ meta }) => meta.layout)
        .map(({ meta }) => meta.layout)
        .shift()

      this.changeLayout(layout)
    },
    changeLayout(newLayout) {
      const newLayoutName = newLayout
      this.appLayoutName = newLayoutName
    }
  }
}
</script>
