export const APP_ERROR_NAME = 'APP'

export class KioskError extends Error {
  constructor(code, message, type) {
    super(code || message)
    this.name = APP_ERROR_NAME
    this.type = type
    this.code = code
    this.message = message
  }

  getMessage() {
    return this.message
  }
  getType() {
    return this.type
  }
  getStatus() {
    return this.status
  }

  getCode() {
    return this.code
  }

  static from(err) {
    throw new Error('Implementation required')
  }

  static is(err) {
    throw new Error('Implementation required')
  }
}

export default KioskError
