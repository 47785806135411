<template>
  <VFooter app flat class="pa-2 pl-4">
    <div
      class="caption grey--text font-weight-medium"
      v-text="$t('app.kiosk.poweredBy')"
    ></div>
    <VImg
      :height="currentLogo.height"
      :width="currentLogo.width"
      v-bind="currentLogo.attrs"
      contain
    />
  </VFooter>
</template>
<script>
import { SETTING_TRACKTIK_GREY_LOGO } from '@/lib/kiosk'

export default {
  name: 'KioskFooterBrand',
  inheritAttrs: false,
  props: {
    logo: {
      type: Object,
      default() {
        return {
          src: SETTING_TRACKTIK_GREY_LOGO,
          alt: 'login-brand-logo'
        }
      }
    },
    logoSize: {
      type: Object,
      default() {
        return {
          xs: [55, 24],
          md: [155, 24],
          lg: [255, 25]
        }
      }
    }
  },
  computed: {
    currentLogo() {
      const [width, height] = this.logoSize.lg

      return {
        attrs: this.logo,
        width,
        height
      }
    }
  }
}
</script>
<style>
.v-image__image--contain {
  background-position: left !important;
}
</style>
