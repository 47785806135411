import Api, { RestApiConnector, apiURLFormat } from 'tracktik-sdk'
import merge from 'lodash/merge'

const {
  NODE_ENV,
  VUE_APP_KIOSK_REST_PROTOCOL,
  VUE_APP_KIOSK_REST_PATH,
  VUE_APP_KIOSK_REST_VERSION
} = process.env

const DEFAULT_PROTOCOL =
  VUE_APP_KIOSK_REST_PROTOCOL || (NODE_ENV === 'production' ? 'https' : 'http')
const DEFAULT_PATH = VUE_APP_KIOSK_REST_PATH || ''
const DEFAULT_API_VERSION = VUE_APP_KIOSK_REST_VERSION || 'v2019-01-01'

export const apiService = (domain, options) => {
  if (!domain) {
    return
  }

  const restApiConnectorOptions = merge(
    {
      domainName: domain,
      protocol: DEFAULT_PROTOCOL,
      apiPath: DEFAULT_PATH,
      timeout: 10000,
      headers: {
        Accept: `application/vnd.tracktik.${DEFAULT_API_VERSION}+json`
      }
    },
    options
  )

  const connector = new RestApiConnector(restApiConnectorOptions)
  return new Api(connector)
}

export const kioskService = ({ domain }, options) => {
  const api = apiService(domain, options)
  return api && api.apps.kiosk
}

export const adminService = ({ domain, currentUser }, options) => {
  const { token } = (currentUser && currentUser.auth) || {}

  if (!token) {
    return
  }

  return apiService(domain, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    ...options
  })
}

export const baseApiURL = (value) => {
  return value && apiURLFormat(value, DEFAULT_PROTOCOL, DEFAULT_PATH)
}

export default {
  kioskService,
  adminService
}
