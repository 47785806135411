<template functional>
  <VCard flat color="transparent" v-bind="data.attrs">
    <VContainer fluid class="pa-3">
      <VRow>
        <VCol>
          <label
            class="subheading grey--text"
            v-text="parent.$t('app.kiosk.assistance.title')"
          />
          <br />
          <VIcon :color="'primary'" size="30" v-text="'phone'" />
          <label
            class="primary--text font-weight-bold headline"
            v-text="props.phoneNumber"
          />
        </VCol>
      </VRow>
    </VContainer>
  </VCard>
</template>

<script>
export default {
  name: 'KioskHelpDeskToolbarItem',
  inheritAttrs: false,
  props: {
    phoneNumber: {
      type: String,
      required: true
    }
  }
}
</script>
