import ShiftAction from './shift-action'

export const ACTION_NAME = 'EARLY_CLOCK_IN'
export class EarlyClockInAction extends ShiftAction {
  constructor(trigger, meta = {}) {
    meta.icon = 'img/icons/clockin.svg'
    meta.iconType = 'svg'
    super(ACTION_NAME, trigger, meta)
  }
}

export default EarlyClockInAction
