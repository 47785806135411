import VuexPersistence from 'vuex-persistedstate'
import * as kioskModule from './modules/kiosk'
import helpers from './helpers'
import * as Sentry from '@sentry/browser'
import migration from './modules/migrations'

const KIOSK_NS_DEFAULT = 'kiosk'

export const makeKioskStore = (options = {}) => {
  const persistance = VuexPersistence(options.persistence)
  const migrate = migration(options.migrations)

  return (store) => {
    store.registerModule(KIOSK_NS_DEFAULT, kioskModule.default)

    if (process.env.NODE_ENV !== 'test') {
      migrate(store)
      persistance(store)
    }

    store.subscribe((_, state) => {
      Sentry.configureScope((scope) => {
        scope.setTag('installation_code', state.kiosk.installationCode)
        scope.setTag('domain', state.kiosk.domain)
        scope.setTag('device_uuid', state.kiosk.deviceUUID)
        scope.setUser(
          state.kiosk.currentUser ? state.kiosk.currentUser.user : {}
        )
      })
    })

    store.subscribe((mutation, state) => {
      if (mutation.type === 'kiosk/SET_ERROR' && mutation.payload) {
        Sentry.withScope((scope) => {
          scope.setExtra('error', mutation.payload)
          Sentry.captureException(mutation.payload)
        })
      }
    })

    const $kiosk = helpers.createKioskHelper({
      store,
      modules: {
        [KIOSK_NS_DEFAULT]: kioskModule
      }
    })

    store.$kiosk = $kiosk
  }
}

export default makeKioskStore()
