import uuid from 'uuid'
import { Setting } from '@/lib/kiosk'
export const DEVICE_UUID = process.env.VUE_APP_KIOSK_DEVICE_UUID || uuid.v4()

export const fromEnv = (key, transformer) => {
  const transformerDefault = (value) => {
    const isFalsy = [null, false, 'false', ''].includes(value)
    return isFalsy ? null : value
  }

  transformer = transformer || transformerDefault
  const value = process.env[key] || null
  return transformer(value)
}

export default () => ({
  deviceUUID: DEVICE_UUID,
  domain: fromEnv('VUE_APP_KIOSK_REST_DOMAIN_DEFAULT'),
  installationCode: fromEnv('VUE_APP_KIOSK_REST_INSTALL_CODE_DEFAULT'),
  permissions: [],
  installed: false,
  setting: Setting.fromInitialState(),
  currentUser: null,
  lastError: null,
  auth: {
    url: null,
    token: null
  }
})
