export const convertToOneLine = (shiftAddress, addressPayload) => {
  const valueOmitted = [null, undefined, '']
  return shiftAddress
    .map((partKey) => addressPayload[partKey])
    .filter((part) => !valueOmitted.includes(part))
    .map((part) => part.trim())
    .join(', ')
}

export class ShiftAddress {
  constructor(payload) {
    this.payload = payload || {}

    Object.defineProperties(this, {
      addressOneLine: {
        enumerable: true,
        get() {
          return ShiftAddress.convertToOneLine(this)
        }
      }
    })
  }

  static convertToOneLine(shiftAddress) {
    return convertToOneLine(
      ['address', 'address_suite', 'city', 'state', 'zip', 'country'],
      shiftAddress.payload
    )
  }

  static fromPayload(payload) {
    if (!payload) {
      return new ShiftAddress({
        address: '',
        address_suite: '',
        city: '',
        country: '',
        state: '',
        zip: ''
      })
    }

    return new ShiftAddress(payload)
  }
}

export default ShiftAddress
