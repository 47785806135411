import { Setting } from '@/lib/kiosk'
import get from 'lodash/get'
import propertyOf from 'lodash/propertyOf'
import moment from 'moment'

export default {
  permissions(state, getters) {
    const currentUser = getters.currentUser || {}
    const userPermissions = (currentUser.auth || {}).permissions || []

    const perms = state.permissions.concat(userPermissions)

    if (!getters.isInstalled) {
      perms.push('install:*')
    }

    if (getters.isInstalled) {
      perms.push('dial:*')
    }

    const shiftControlAuthModeOk = [
      'verifyEmployeeCredentials',
      'verifyEmployeeCustomId'
    ].includes(currentUser.authMode)

    if (shiftControlAuthModeOk) {
      perms.push('shift-control:*')
    }

    return perms
  },
  matchPermissions(state, getters) {
    return (roles) => {
      const perms = getters.permissions
      return roles.every((rolePath) => {
        return perms.includes(rolePath)
      })
    }
  },
  isInstalled(state) {
    return state.installed === true
  },
  currentUser(state) {
    return state.currentUser
  },
  currentUserId(state) {
    return get(state, 'currentUser.user.id')
  },
  licenseId(state) {
    return get(state, 'auth.licenseId')
  },
  setting(state) {
    return state.setting
      ? new Setting(state.setting)
      : Setting.fromInitialState()
  },
  appToken(state) {
    return get(state, 'auth.appToken', false)
  },
  userToken(state) {
    return get(state, 'currentUser.auth.token', false)
  },
  shiftDisapprovedResume(state) {
    const shiftProp = propertyOf(state.currentUser.shift)
    const formatDate = (date) => moment(date).format('LLL')

    return {
      clockin_summary: shiftProp('breakCompliance.computedSessions.clockIn'),
      clockout_summary: {
        ...shiftProp('breakCompliance.computedSessions.clockOut'),
        actual: formatDate(new Date())
      },
      break_summary: [
        shiftProp('breakCompliance.computedSessions.mealBreak'),
        shiftProp('breakCompliance.computedSessions.restBreak')
      ]
    }
  }
}
