export const withVuetify = (options = {}) => (field, vm) => {
  return {
    label: field.$te('label') ? field.$t('label') : undefined,
    placeholder: field.$te('placeholder') ? field.$t('placeholder') : undefined,
    hint: field.$te('hint') ? field.$t('hint') : undefined,
    messages: field.$te('messages') ? field.$t('messages') : undefined
  }
}

export default withVuetify
