export class ShiftOwner {
  constructor(payload) {
    this.payload = payload || {}

    Object.defineProperties(this, {
      firstName: {
        enumerable: true,
        get() {
          return this.payload.user.firstName
        }
      },

      lastName: {
        enumerable: true,
        get() {
          return this.payload.user.lastName
        }
      },

      fullName: {
        enumerable: true,
        get() {
          return [this.firstName, this.lastName].join(' ')
        }
      },

      email: {
        enumerable: true,
        get() {
          return this.payload.user.email
        }
      },

      mobile: {
        enumerable: true,
        get() {
          return this.payload.user.primaryPhone
        }
      },
      avatar: {
        enumerable: true,
        get() {
          return this.payload.user.avatar
        }
      },
      help: {
        enumerable: true,
        get() {
          return '514-430-1402'
        }
      }
    })
  }

  static fromPayload(payload) {
    if (!payload || !payload.user) {
      return new ShiftOwner({
        user: {
          firstName: '',
          lastName: '',
          fullName: '',
          email: '',
          primaryPhone: '',
          avatar: ''
        }
      })
    }
    return new ShiftOwner(payload)
  }
}

export default ShiftOwner
