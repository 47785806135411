<template>
  <VApp id="inspire">
    <Component :is="toolbar.is" v-if="toolbar" v-bind="toolbar.props" />
    <VMain>
      <VContainer fluid py-0 fill-height>
        <slot
          :app="app"
          :content-props="contentProps"
          :content-listeners="contentListeners"
        />
      </VContainer>
    </VMain>
    <Component :is="footer" />
  </VApp>
</template>

<script>
import KioskFooterBrand from '@/components/KioskFooterBrand'
import KioskBasicToolbar from '@/views/toolbars/KioskBasicToolbar'

export default {
  name: 'KioskPageLayout',
  props: {
    app: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      toolbar: null
    }
  },
  computed: {
    meta() {
      const route = [...this.$route.matched].filter(({ meta }) => meta).shift()
      return route.meta
    },
    contentListeners() {
      return {
        'change:toolbar-items': this.changeToolbarItems
      }
    },
    contentProps() {
      return {}
    },
    metaToolbar() {
      const { component, ...props } = this.meta.layoutToolbar || {}
      return {
        is: component || KioskBasicToolbar,
        props
      }
    },
    footer() {
      return KioskFooterBrand
    }
  },
  watch: {
    metaToolbar: {
      immediate: true,
      handler() {
        this.toolbar = {
          ...(this.toolbar || {}),
          ...this.metaToolbar
        }
      }
    }
  },
  methods: {
    changeToolbarItems(items) {
      const toolbarItems = this.toolbar.props ? this.toolbar.props.items : {}
      this.toolbar.props.items = {
        ...toolbarItems,
        ...(items || {})
      }
    }
  }
}
</script>
<style scoped>
/* Provide better right-edge spacing when using an icon button there. */
.snack >>> .v-snack__content {
  padding-right: 16px;
}
</style>
