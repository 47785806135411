export default {
  name: 'KioskIconSvg',
  props: {
    filepath: {
      type: String,
      defualt: ''
    },
    fill: {
      type: String,
      defualt: ''
    },
    stroke: {
      type: String,
      defualt: ''
    },
    size: {
      type: String,
      defualt: ''
    },
    id: {
      type: String,
      defualt: ''
    }
  },
  data() {
    return {
      isSVGReady: false
    }
  },
  mounted() {
    // generate inline svg
    this.generateInlineSVG()
  },
  watch: {
    filepath(val) {
      // re-generate inline svg
      this.generateInlineSVG(val)
    },
    fill(val) {
      this.updateSVGStyle('fill', val)
    },
    stroke(val) {
      this.updateSVGStyle('stroke', val)
    },
    width(val) {
      this.updateSVGStyle('width', val)
    },
    height(val) {
      this.updateSVGStyle('height', val)
    }
  },
  methods: {
    /* load a svg image with xml http request to get an inlined svg and append it to this component */
    generateInlineSVG() {
      const context = this

      if (!this.filepath) {
        context.$log.debug('Filepath is undefined')
        return
      }

      // reset first
      this.isSVGReady = false
      let svgElement = this.$el.getElementsByTagName('svg')[0]
      if (svgElement) {
        this.$el.removeChild(svgElement)
      }

      // Get the contents of the SVG
      let request = new XMLHttpRequest()
      request.open('GET', this.filepath, true)
      request.onload = function() {
        if (request.status >= 200 && request.status < 400) {
          // Setup a dom parser to convert the response to text/xml in order for it
          // to be manipulated and changed
          let domParser = new DOMParser()
          let result = domParser.parseFromString(
            request.responseText,
            'text/xml'
          )
          let inlinedSVG = result.getElementsByTagName('svg')[0]

          if (!inlinedSVG) {
            context.$log.debug(
              'No svg element found. Did you pass a valid .svg file?'
            )
            return
          }

          if (context.id) inlinedSVG.id = context.id
          inlinedSVG.style.width = context.size + 'px'
          inlinedSVG.style.height = context.size + 'px'
          inlinedSVG.style.fill = context.fill
          inlinedSVG.style.stroke = context.stroke

          context.$el.appendChild(inlinedSVG)

          // now the svg is ready to show
          this.isSVGReady = true
          context.$emit('ready')
        } else {
          context.$log.debug(
            'There was an error retrieving the source of the SVG.'
          )
        }
      }

      request.onerror = function() {
        context.$log.debug(
          'There was an error connecting to the origin server.'
        )
      }

      request.send()
    },
    /* update SVG's style */
    updateSVGStyle(property, value) {
      let svgElement = this.$el.getElementsByTagName('svg')[0]
      if (svgElement) {
        svgElement.style[property] = value
      } else {
        this.$log.debug('No svg element found. Did you pass a valid .svg file?')
      }
    }
  },
  render(createElement) {
    return createElement('i', {
      class: ['div']
    })
  }
}
