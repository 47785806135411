import Vue from 'vue'
import { KioskFormError } from '@/errors'
const noop = () => {}

export const FORM_CONTROL_EVENTS = {
  change: 'change',
  submitted: 'submitted'
}
export class FormControl {
  constructor(name, state, submitHandler, validations) {
    this.submitHandler = submitHandler || noop
    this.fields = {}
    this.hit = 0
    this.$bus = new Vue()

    this.on = (...args) => {
      this.$bus.$on(...args)
    }

    this.$state = () => state

    Object.defineProperties(this, {
      name: {
        enumerable: true,
        get() {
          return name
        }
      },
      $v: {
        enumerable: true,
        get() {
          const { $v } = this.$state().$formState.state
          return $v
        }
      },
      state: {
        enumerable: true,
        get() {
          const { data, ...rest } = this.$state()
          return rest
        }
      },
      data: {
        enumerable: true,
        get() {
          return this.$state().data
        }
      }
    })
  }

  setFields(fields = {}) {
    Object.entries(fields).reduce(($fields, [name, field]) => {
      $fields[name] = field
      field.$form = this
      field.on(FORM_CONTROL_EVENTS.change, this.onFieldChange.bind(this))
      return $fields
    }, this.fields)
  }

  onFieldChange(field) {
    const { data } = this.$state()
    data[field.name] = field.value
    this.$bus.$emit('change:field', data[field.name])
  }

  get isLoading() {
    const $state = this.$state()
    return $state.loading
  }

  get isSubmitted() {
    const $state = this.$state()
    return $state.submitted
  }

  async submit() {
    const $state = this.$state()

    if ($state.loading) {
      return
    }

    try {
      $state.setError(null)
      $state.setLoading(true)
      await this.submitHandler($state.data, this.fields)
      $state.setSubmitted(true)
      this.$bus.$emit(FORM_CONTROL_EVENTS.submitted, true)
    } catch (err) {
      $state.setSubmitted(false)
      $state.setError(KioskFormError.from(err))
      this.$bus.$emit(FORM_CONTROL_EVENTS.submitted, false)
      throw err
    } finally {
      $state.setLoading(false)
    }
  }
}

export default FormControl
