import merge from 'lodash/merge'
import inRange from 'lodash/inRange'
import moment from 'moment'

export class ShiftBreakSessionTreahold {
  static defaultOptions() {
    return {
      startGracePeriodInMinutes: 0,
      breakGracePeriodStart: 0,
      breakGracePeriodEnd: 0,
      extraBreakMinutesTolerance: 0
    }
  }

  constructor(payload, options = {}) {
    this.payload = payload
    this.options = merge(ShiftBreakSessionTreahold.defaultOptions(), options)
  }

  static isClockInOverTreahold(clockInBreakSession, options = {}) {
    const { startGracePeriodInMinutes } = options

    const scheduledGracedPeriod = moment(clockInBreakSession.expected).add(
      startGracePeriodInMinutes,
      'minutes'
    )

    return moment(clockInBreakSession.actual).isAfter(
      scheduledGracedPeriod,
      'minutes'
    )
  }

  static isClockOutOverTreahold(clockOutBreakSession, options = {}) {
    return (
      moment(clockOutBreakSession.actual).isSame(
        clockOutBreakSession.expected,
        'minutes'
      ) === false
    )
  }

  static isMealBreakOverTreahold(mealBreakBreakSession, options = {}) {
    const start = mealBreakBreakSession.expectedInMinutes
    const end =
      mealBreakBreakSession.expectedInMinutes +
      options.extraBreakMinutesTolerance

    return (
      inRange(mealBreakBreakSession.actualInMinutes, start, end + 1) === false
    )
  }

  static isRestBreakOverTreahold(restBreakBreakSession, options) {
    const start = restBreakBreakSession.expectedInMinutes
    const end =
      restBreakBreakSession.expectedInMinutes +
      options.extraBreakMinutesTolerance

    return (
      inRange(restBreakBreakSession.actualInMinutes, start, end + 1) === false
    )
  }

  static makeIsOverTreaholdByTypes(type, options) {
    const treaholdByTypes = {
      clockout: (breakSession) =>
        ShiftBreakSessionTreahold.isClockOutOverTreahold(breakSession, options),
      clockin: (breakSession) =>
        ShiftBreakSessionTreahold.isClockInOverTreahold(breakSession, options),
      mealbreak: (breakSession) =>
        ShiftBreakSessionTreahold.isMealBreakOverTreahold(
          breakSession,
          options
        ),
      restbreak: (breakSession) =>
        ShiftBreakSessionTreahold.isRestBreakOverTreahold(breakSession, options)
    }

    return treaholdByTypes[type] || (() => false)
  }

  isCompliant(breakSession) {
    return this.isOverThreahold(breakSession) === false
  }

  isOverThreahold(breakSession) {
    const isOverThreahold = ShiftBreakSessionTreahold.makeIsOverTreaholdByTypes(
      breakSession.type,
      this.options
    )

    return isOverThreahold(breakSession)
  }

  static from(breakCompliancePayload) {
    return new ShiftBreakSessionTreahold(breakCompliancePayload)
  }
}

export default ShiftBreakSessionTreahold
