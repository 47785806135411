import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'
import * as mutationTypes from './mutation-types'
import * as actionTypes from './action-types'

export const kiosk = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state
}

export { mutationTypes, actionTypes, state }

export default kiosk
