import { SHIFT_ACTION_NAMES } from '@/lib/kiosk/shift-action'

export const BACK = 'back'
export const EARLY_CLOCK_IN = SHIFT_ACTION_NAMES.EARLY_CLOCK_IN
export const CLOCK_IN = SHIFT_ACTION_NAMES.CLOCK_IN
export const CLOCK_OUT = SHIFT_ACTION_NAMES.CLOCK_OUT
export const END_BREAK = SHIFT_ACTION_NAMES.END_BREAK
export const FETCH = 'fetch'
export const INSTALL = 'install'
export const LOGOUT = 'logout'
export const UNINSTALL = 'uninstall'
export const SAVE_SETTING = 'saveSetting'
export const START_BREAK = SHIFT_ACTION_NAMES.START_BREAK
export const START_BREAK_MEAL = SHIFT_ACTION_NAMES.START_BREAK_MEAL
export const START_BREAK_REST = SHIFT_ACTION_NAMES.START_BREAK_REST
export const VERIFY_EMPLOYEE_CREDENTIALSS = 'verifyEmployeeCredentials'
export const VERIFY_EMPLOYEE_CUSTOM_ID = 'verifyEmployeeCustomId'
export const FETCH_SCHEDULE_INFORMATION = 'fetchScheduleInformation'
export const DISAPPROVE_SHIFT = 'disapproveShift'
export const WINDOW_RELOAD = 'WINDOW_RELOAD'
