<template>
  <VApp id="inspire">
    <VMain>
      <VDialog
        fullscreen
        hide-overlay
        no-click-animation
        transition="dialog-bottom-transition"
        :value="true"
      >
        <DialogToolbar v-bind="toolbarProps" />
        <slot
          :app="app"
          :content-props="contentProps"
          :content-listeners="contentListeners"
        />
        <DialogFooter v-bind="footerProps" />
      </VDialog>
    </VMain>
  </VApp>
</template>

<script>
import DialogFooter from './DialogFooter'
import DialogToolbar from './DialogToolbar'

export default {
  name: 'KioskDialogLayout',
  components: {
    DialogFooter,
    DialogToolbar
  },
  props: {
    app: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      toolbar: {
        actions: {}
      },
      footer: {}
    }
  },
  computed: {
    meta() {
      const meta = [...this.$route.matched]
        .reverse()
        .filter(({ meta }) => meta)
        .shift()

      return meta
    },
    title() {
      return this.$t(`app.kiosk.${this.$route.name || this.meta.title}`)
    },

    contentListeners() {
      return {
        'change:toolbar-actions': this.changeToolbarActions
      }
    },

    contentProps() {
      return {}
    },

    toolbarActions() {
      return this.toolbar.actions
    },

    toolbarProps() {
      return {
        actions: this.toolbarActions,
        title: this.title
      }
    },

    footerProps() {
      const lastUpdate = this.$d(this.app.lastUpdate, 'short')
      return {
        version: this.$t('app.kiosk.version.label', {
          version: this.app.version
        }),
        lastUpdate: this.$t('app.kiosk.lastUpdate.label', { date: lastUpdate })
      }
    }
  },
  methods: {
    changeToolbarActions(actions) {
      this.toolbar.actions = actions
    }
  }
}
</script>
