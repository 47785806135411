import Vue from 'vue'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@/styles/main.scss'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#1139a2',
        secondary: '#4c4c4c',
        accent: '#82B1FF',
        error: '#e23a3a',
        info: '#2196F3',
        success: '#43cb67',
        warning: '#FFC107',
        cardPrimary: '#efeff0',
        footerPrimary: '#f7f5f7'
      }
    }
  }
})
