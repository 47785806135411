<script>
import KioskGuard from '@/components/KioskGuard'

export default {
  name: 'KioskGuardInstallationRequired',
  functional: true,
  render(h, context) {
    const { $store } = context.parent
    const isInstalled = $store.getters['kiosk/isInstalled']

    const accepted = (props) => {
      if (!context.scopedSlots.default) {
        return null
      }

      return context.scopedSlots.default(props)
    }

    const rejected = (props) => {
      if (!context.scopedSlots.rejected) {
        return null
      }

      return context.scopedSlots.rejected(props)
    }

    const resolving = (props) => {
      if (!context.scopedSlots.resolving) {
        return null
      }

      return context.scopedSlots.resolving(props)
    }

    return h(KioskGuard, {
      props: {
        ...context.props,
        handler() {
          return isInstalled
        }
      },
      scopedSlots: {
        default: accepted,
        rejected,
        resolving
      }
    })
  }
}
</script>
