<script>
import KioskGuard from '@/components/KioskGuard'

export default {
  name: 'KioskGuardBreakManagementRequired',
  functional: true,
  inheritAttrs: false,
  render(h, context) {
    const { $store } = context.parent

    const accepted = (props) => {
      if (!context.scopedSlots.default) {
        return null
      }

      return context.scopedSlots.default(props)
    }

    const rejected = (props) => {
      if (!context.scopedSlots.rejected) {
        return null
      }

      return context.scopedSlots.rejected(props)
    }

    const resolving = (props) => {
      if (!context.scopedSlots.resolving) {
        return null
      }

      return context.scopedSlots.resolving(props)
    }

    return h(KioskGuard, {
      props: {
        ...context.props,
        handler() {
          const { shift } = $store.getters['kiosk/currentUser'] || {}

          if (!shift || !shift.hasOwnProperty('breakCompliance')) {
            return false
          }

          return shift.breakCompliance.breakManagementEnableFeature
        }
      },
      scopedSlots: {
        default: accepted,
        rejected,
        resolving
      }
    })
  }
}
</script>
