<template functional>
  <VFooter fixed flat class="footerPrimary">
    <VContainer pa-0>
      <VRow wrap row class="ml-2">
        <label class="d-flex" v-text="props.version" />
        <VDivider vertical class="mx-3" />
        <label class="d-flex" v-text="props.lastUpdate" />
      </VRow>
    </VContainer>
  </VFooter>
</template>

<script>
export default {
  name: 'DialogFooter',
  props: {
    version: {
      type: String,
      required: true
    },
    lastUpdate: {
      type: String,
      required: true
    }
  }
}
</script>
