import ShiftAction from './shift-action'

export const ACTION_NAME = 'DISAPPROVE_CLOCKOUT'

export class DisapproveClockOutAction extends ShiftAction {
  constructor(trigger, meta = {}) {
    super(ACTION_NAME, trigger, meta)
  }
}

export default DisapproveClockOutAction
