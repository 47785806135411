import makeInstallationGuard from './installation-guard'
import makePermissionGuard from './permission-guard'

export const ROUTER_DEFAULT_GUARDS = [
  [makeInstallationGuard, 'install.index'],
  [makePermissionGuard]
]

export default (context, router) => {
  const { app, guards } = context

  router.beforeEach(async (to, from, next) => {
    const guardRedirect = (guards || ROUTER_DEFAULT_GUARDS)
      .map(([makeGuard, options]) => makeGuard(context, options))
      .map((guard) => guard(to, from))
      .find((guardRedirect) => guardRedirect !== false)

    if (!guardRedirect) {
      app.$log.debug('[guard] router.before pass next', { from })
      return next()
    }

    if (guardRedirect.name === to.name) {
      app.$log.debug('[guard] router.before redirect target same route', from)
      return next()
    }

    app.$log.debug('[guard] router.before guard redirect ', {
      from,
      guard: guardRedirect
    })
    return next(guardRedirect)
  })

  router.afterEach(() => {})

  router.beforeResolve((to, from, next) => {
    const guardRedirect = (guards || ROUTER_DEFAULT_GUARDS)
      .map(([makeGuard, options]) => makeGuard(context, options))
      .map((guard) => guard(to, from))
      .find((guardRedirect) => guardRedirect !== false)

    if (!guardRedirect) {
      app.$log.debug('[guard] router.beforeResolve pass next', { from })
      return next()
    }

    if (guardRedirect.name === to.name) {
      app.$log.debug(
        '[guard] router.beforeResolve redirect target same route',
        from
      )
      return next()
    }

    app.$log.debug('[guard] router.beforeResolve guard redirect ', {
      from,
      guard: guardRedirect
    })

    return next(guardRedirect)
  })

  return router
}
