import consola from 'consola'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

export const isEnable = (options) => {
  if (process.env.NODE_ENV !== 'production') {
    return false
  }

  if (['true', true].includes(process.env.VUE_APP_SENTRY) !== true) {
    return false
  }

  if (!options.dsn) {
    consola.warn('Plugins Sentry options dsn required')
    return
  }

  return true
}

export function install(Vue, options) {
  if (!Vue.prototype.$log) {
    Vue.prototype.$log = consola
  }

  if (!isEnable(options)) {
    return
  }

  if (Array.isArray(options.release)) {
    options.release = options.release.find((candidate) => {
      return ![null, false, '', undefined].includes(candidate)
    })
  }

  Vue.prototype.$sentry = Sentry

  Sentry.init({
    dsn: options.dsn,
    release: options.release,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true
      })
    ]
  })

  Sentry.configureScope((scope) => {
    scope.setTag(
      'application',
      options.packageName || process.env.VUE_APP_PKG_NAME
    )
  })

  consola.addReporter({
    log({ level, args }) {
      const { VUE_APP_SENTRY_LOG_LEVEL } = process.env
      if (level > parseInt(VUE_APP_SENTRY_LOG_LEVEL)) {
        return
      }

      // https://github.com/nuxt/consola/blob/master/src/types.js
      Sentry.captureException(...args)
    }
  })
}

export default { install }
