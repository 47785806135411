import consola from 'consola'
import NoSleep from 'nosleep.js'

const withWakeLock = async () => {
  let wakeLockObj
  let wakeLockRequest

  try {
    consola.debug('[wake-lock] use web api')

    wakeLockObj = await navigator.getWakeLock('screen')
    wakeLockRequest = wakeLockObj.createRequest()
    return () => {
      wakeLockRequest.cancel()
      wakeLockRequest = null
    }
  } catch (err) {
    consola.error('[wake-lock]', err)
    throw err
  }
}

const withNoSleep = async () => {
  consola.debug('[wake-lock] use nosleep.js')

  const noSleep = new NoSleep()
  document.addEventListener(
    'click',
    function enableNoSleep() {
      document.removeEventListener('click', enableNoSleep, false)
      noSleep.enable()
    },
    false
  )

  return () => {
    noSleep.disable()
  }
}

export const enableWakeLock = async () => {
  if ('getWakeLock' in navigator) {
    return withWakeLock()
  } else {
    return withNoSleep()
  }
}

export default enableWakeLock
