import consola from 'consola'
import VueAnalytics from 'vue-analytics'

export function install(Vue, options) {
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  if (['true', true].includes(process.env.VUE_APP_GA) !== true) {
    return
  }

  if (!options.apiKey) {
    consola.warn('Plugins GA options apiKey required')
    return
  }

  Vue.use(VueAnalytics, {
    id: options.apiKey,
    ...(options || {})
  })
}

export default { install }
