import Vue from 'vue'
import Vuelidate from 'vuelidate'
import FastClick from 'fastclick'
import store from './store'
import router from './router'
import { applyRouterGuards } from './guards'
import i18n from './i18n'
import './plugins/logger'
import vuetify from '@/plugins/vuetify'
import Vendors from './plugins/vendors'
import App from './App'
import '@/registerServiceWorker'
import * as Sentry from '@sentry/browser'

FastClick.attach(window.document.body)

Vue.config.productionTip = process.env.NODE_ENV !== 'production'

Vue.use(Vendors)
Vue.use(Vuelidate)

const app = new Vue({
  store,
  i18n,
  router,
  vuetify,
  render: (h) => h(App)
})

applyRouterGuards({ store, app }, router)

if (process.env.NODE_ENV !== 'production') {
  setTimeout(() => app.$mount('#app'), 50)
} else {
  Sentry.captureMessage(`running kiosk@v${process.env.VUE_APP_PKG_VERSION}`)
  app.$mount('#app')
}
