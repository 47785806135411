<script>
import KioskGuard from '@/components/KioskGuard'

export default {
  name: 'KioskGuardPermissionRequired',
  functional: true,
  render(h, context) {
    const { $store } = context.parent

    const isAuthorized = context.props.perms.every((perm) => {
      return $store.getters['kiosk/permissions'].includes(perm)
    })

    const accepted = (props) => {
      if (!context.scopedSlots.default) {
        return null
      }

      return context.scopedSlots.default(props)
    }

    const rejected = (props) => {
      if (!context.scopedSlots.rejected) {
        return null
      }

      return context.scopedSlots.rejected(props)
    }

    const resolving = (props) => {
      if (!context.scopedSlots.resolving) {
        return null
      }

      return context.scopedSlots.resolving(props)
    }

    return h(KioskGuard, {
      ...context.props,
      props: {
        handler() {
          return isAuthorized
        }
      },
      scopedSlots: {
        default: accepted,
        rejected,
        resolving
      }
    })
  }
}
</script>
