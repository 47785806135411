export const makeInstallationGuard = ({ store }, redirect) => (
  to,
  from,
  next
) => {
  const isInstalled = store.getters['kiosk/isInstalled']

  if (isInstalled === true) {
    return false
  }

  if (to.name === redirect.name) {
    return false
  }

  return { name: redirect }
}

export default makeInstallationGuard
