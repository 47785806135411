<template>
  <VToolbar flat>
    <VBtn
      v-if="close"
      :icon="!!close.icon"
      v-bind="close.btn"
      @click="triggerClose()"
    >
      <VIcon v-if="!!close.icon" large v-text="close.icon" />
      <span v-if="close.text" v-text="close.text" />
    </VBtn>
    <VToolbarTitle
      v-if="title"
      class="display-1 font-weight-light primary--text"
      v-text="title"
    />
    <VSpacer></VSpacer>
    <template v-for="action in actionPositions.end">
      <slot :name="action.name" :action="action">
        <VBtn
          :key="action.name"
          v-bind="action.btn"
          @click="triggerAction(action)"
        >
          <VIcon v-if="!!action.icon" v-text="action.icon" />
          <span v-if="action.text" v-text="action.text" />
        </VBtn>
      </slot>
    </template>
  </VToolbar>
</template>

<script>
export default {
  name: 'DialogToolbar',
  props: {
    actions: {
      type: Object,
      required: true
    },
    title: {
      type: [Boolean, String],
      default: false
    }
  },
  data() {
    return {
      $actions: {}
    }
  },
  computed: {
    close() {
      return this.actions.close
    },
    actionPositions() {
      const { close, ...actions } = this.actions
      return {
        end: Object.values(actions)
      }
    }
  },
  watch: {
    actions(newActions) {
      this.$actions = newActions
    }
  },
  methods: {
    triggerAction(action) {
      action.trigger()
    },

    triggerClose() {
      return this.$actions.close.trigger()
    }
  }
}
</script>
