<script>
export default {
  name: 'KioskGuard',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    handler: {
      type: Function,
      required: true
    },
    timeout: {
      type: [Number, String],
      default: 200
    },
    optimistic: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      reason: null,
      isDelayElapsed: false,
      resolved: false,
      rejected: false,
      accepted: this.optimistic === true
    }
  },
  watch: {
    handler: {
      immediate: true,
      async handler(fn) {
        try {
          this.resolved = false
          this.watchDelay()
          const res = await fn()
          if (typeof res === typeof String()) {
            this.reason = res
            this.accepted = false
            this.rejected = true
          } else if (typeof res === typeof Boolean()) {
            this.reason = null
            this.accepted = res
            this.rejected = !res
          } else {
            this.accepted = true
            this.rejected = false
          }
        } catch (err) {
          this.accepted = false
          this.rejected = true
        } finally {
          this.resolved = true
        }
      }
    },
    rejected(rejectedValue) {
      if (rejectedValue) {
        this.$emit('rejected')
      }
    },
    accepted(acceptedValue) {
      if (acceptedValue) {
        this.$emit('accepted')
      }
    }
  },
  methods: {
    watchDelay() {
      if (this.timeout > 0) {
        this.isDelayElapsed = false
        if (this.$timerId) {
          clearTimeout(this.timerId)
        }

        this.$timerId = setTimeout(
          () => (this.isDelayElapsed = true),
          this.timeout
        )
      } else {
        this.isDelayElapsed = true
      }
    }
  },
  render(h) {
    if (this.$scopedSlots.combined) {
      const node = this.$scopedSlots.combined({
        reason: this.reason,
        isResolving: !this.resolved,
        rejected: this.rejected,
        accepted: this.accepted
      })

      return Array.isArray(node) ? convertVNodeArray(h, this.tag, node) : node
    }

    if (this.rejected) {
      return getSlotVNode(this, h, 'rejected', this.error)
    }

    if (this.accepted) {
      return getSlotVNode(this, h, 'default')
    }

    return getSlotVNode(this, h, 'resolving')
  }
}

function convertVNodeArray(h, wrapperTag, nodes) {
  if (nodes.length > 1 || !nodes[0].tag) {
    return h(
      wrapperTag,
      {
        staticClass: 'kiosk-guard'
      },
      nodes
    )
  }

  return nodes[0]
}

function getSlotVNode(vm, h, slotName, data) {
  if (vm.$scopedSlots[slotName]) {
    const node = vm.$scopedSlots[slotName](data)
    return Array.isArray(node) ? convertVNodeArray(h, vm.tag, node) : node
  } else if (vm.$slots[slotName]) {
    const node = vm.$slots[slotName]
    return Array.isArray(node) ? convertVNodeArray(h, vm.tag, node) : node
  } else {
    return null
  }
}
</script>
