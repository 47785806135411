<script>
import KioskClock from './KioskClock'

export default {
  name: 'KioskClockI10n',
  inheritAttrs: false,
  props: {
    locale: {
      type: String,
      default() {
        return this.$i18n.locale
      }
    },
    locales: {
      type: Object,
      default() {
        return {
          en: ['ddd, D MMM', 'hh:mm', 'A'],
          fr: ['ddd, D MMM', 'HH:mm'],
          es: ['ddd, D MMM', 'HH:mm']
        }
      }
    },
    value: {
      type: [String, Date],
      default() {
        return new Date()
      }
    }
  },
  computed: {
    format() {
      return this.locales[this.locale]
    }
  },
  render(h) {
    return h(KioskClock, {
      props: {
        locale: this.locale,
        format: this.format,
        value: this.value
      },
      attrs: this.$attrs,
      scopedSlots: this.$scopedSlots
    })
  }
}
</script>
