<template>
  <VSelect
    v-model="selected"
    :items="locales"
    v-bind="$attrs"
    @input="onInput"
  />
</template>

<script>
export default {
  name: 'KioskLocaleSelect',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true
    },
    locales: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selected: this.value
    }
  },
  methods: {
    onInput() {
      this.$emit('input', this.selected)
    }
  }
}
</script>
