import Vue from 'vue'
import * as mutationTypes from './mutation-types'
import stateInitial from './state'
import consola from 'consola'
const parse = require('url-parse')

export default {
  [mutationTypes.RESET_USER](state) {
    state.currentUser = null
  },
  [mutationTypes.SET_CURRENT_USER](state, currentUserState) {
    state.currentUser = currentUserState
  },
  [mutationTypes.SET_DOMAIN](state, domain) {
    const url = parse(domain, {})

    if (!url.protocol) {
      state.domain = url.pathname.split('/').shift()
      return
    }

    if (url.hostname) {
      state.domain = url.hostname
      return
    }

    consola.warn(`SET_DOMAIN with domain(${domain})`)
    state.domain = domain
  },
  [mutationTypes.SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions
  },
  [mutationTypes.SET_ERROR](state, error) {
    state.lastError = error
  },
  [mutationTypes.SET_SETTING](state, setting) {
    state.setting = setting
  },
  [mutationTypes.INSTALL](state, installResponsePayload) {
    const { company, theme, auth, pref } = installResponsePayload
    state.auth = auth

    Vue.set(state.setting, 'phoneNumber', company.primaryPhone)
    Vue.set(state.setting, 'logoCompany', theme.logo)
    Vue.set(state.setting, 'pref', pref)
    Vue.set(state, 'installed', true)
  },
  [mutationTypes.UNINSTALL](state) {
    Object.keys(state).forEach((key) => {
      Vue.delete(state, key)
    })

    Object.entries(stateInitial()).forEach(([key, value]) => {
      Vue.set(state, key, value)
    })
  },
  SIGNIN(state, payload) {
    state.auth = payload.auth
  }
}
