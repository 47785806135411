import ShiftAction from './shift-action'
import { enumerableProps } from '../utils'

export const ACTION_NAME = 'CLOCK_IN'
export class ClockInAction extends ShiftAction {
  constructor(trigger, meta = {}) {
    meta.icon = 'img/icons/clockin.svg'
    meta.iconType = 'svg'
    super(ACTION_NAME, trigger, meta)
    enumerableProps(this, Reflect.ownKeys(ClockInAction.prototype))
  }
}

export default ClockInAction
