import consola from 'consola'
import snakeCase from 'lodash/snakeCase'

/* eslint-disable */
const loadHeapScript = () => {
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
  return window.heap
}
/* eslint-enable */

export const VueHeapMixin = (heapOptions) => ({
  props: {
    [heapOptions.prop]: {
      type: String,
      default: null
    }
  },
  mounted() {
    const value = this[heapOptions.prop]

    if (!value) {
      return
    }

    if (this.$el && this.$el.setAttribute) {
      this.$el.setAttribute(heapOptions.attr, this.$toHeapId(value))
    }
  },
  methods: {
    $toHeapId(value) {
      return heapOptions.transform(value)
    }
  }
})

export function install(Vue, options) {
  const $heapOptions = {
    prop: 'dataHeapId',
    attr: 'data-heap-id',
    transform: snakeCase
  }

  Vue.mixin(VueHeapMixin($heapOptions))

  Object.defineProperty(Vue.prototype, '$heapOptions', {
    get() {
      return { ...$heapOptions }
    }
  })

  Object.defineProperty(Vue.prototype, '$heap', {
    get() {
      if (!window.heap) {
        consola.warn('Heap is not initialized')
      }

      return window.heap
    }
  })

  if (process.env.NODE_ENV !== 'production') {
    return
  }

  if (['true', true].includes(process.env.VUE_APP_HEAP) !== true) {
    return
  }

  if (!options.appId) {
    consola.warn('Heap options appId required')
    return
  }

  loadHeapScript()
  const { appId, ...heapOptions } = options
  window.heap.load(appId, heapOptions)
}

export default { install }
