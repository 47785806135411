import snippet from './snippet.txt'
import consola from 'consola'

export function injectScript(options) {
  return new Promise((resolve, reject) => {
    const head = document.head || document.getElementsByTagName('head')[0]
    const script = document.createElement('script')

    const snippetWithCredentials = snippet
      .replace('${LICENSE_KEY}', options.licenseKey)
      .replace('${APPLICATION_ID}', options.applicationId)

    script.async = true
    script.charset = 'utf8'
    script.innerHTML = snippetWithCredentials
    head.appendChild(script)

    script.onload = resolve
    script.onerror = reject
  })
}

export function install(Vue, options) {
  if (process.env.NODE_ENV !== 'production') {
    return
  }

  if (['true', true].includes(process.env.VUE_APP_NEWRELIC) !== true) {
    return
  }

  if (!options.licenseKey) {
    consola.warn('Plugins newrelic licenseKey required')
    return
  }

  if (!options.applicationId) {
    consola.warn('Plugins newrelic applicationId required')
    return
  }

  injectScript(options)
}

export default { install }
