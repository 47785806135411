import { createNamespacedHelpers } from 'vuex'

export const makeMeta = (creator) => (args) => {
  if (creator) {
    return creator(args)
  }
  return args
}

export const makePayload = (creator) => (args) => {
  if (creator) {
    return creator(args)
  }
  return args
}

export const makeCreateAction = (options = {}) => {
  return (type, payloadCreator, metaCreator) => {
    const meta = makeMeta(metaCreator)
    const payload = makePayload(payloadCreator)

    return (...args) => ({
      type,
      ...payload(args),
      meta: meta(args)
    })
  }
}

export const makeCreateMutation = (options = {}) => {
  return (type, payloadCreator, metaCreator) => {
    const meta = makeMeta(metaCreator)
    const payload = makePayload(payloadCreator)

    return (...args) => ({
      type,
      ...payload(args),
      meta: meta(args)
    })
  }
}

export const createKioskHelper = (options) => {
  const $kiosk = {
    createAction: makeCreateAction(),
    createMutation: makeCreateMutation()
  }

  Object.entries(options.modules).forEach(([ns, mod]) => {
    $kiosk[ns] = createNamespacedHelpers.call(options.store, ns)
  })

  return $kiosk
}

export default {
  createKioskHelper,
  makeMeta,
  makePayload,
  makeCreateAction,
  makeCreateMutation
}
