import Vue from 'vue'
import Vuex from 'vuex'
import plugin from './plugin'
import helpers from './helpers'
import { analyticsMiddleware } from 'vue-analytics'

Vue.use(Vuex)

export { plugin, helpers }

export default new Vuex.Store({
  mutations: {},
  actions: {},
  plugins: [plugin, analyticsMiddleware]
})
