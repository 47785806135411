import ShiftAction from './shift-action'

export const ACTION_NAME = 'START_BREAK'

export class StartBreakAction extends ShiftAction {
  constructor(trigger, meta = {}) {
    meta.icon = 'img/icons/startbreak.svg'
    meta.iconType = 'svg'
    super(ACTION_NAME, trigger, meta)
  }
}

export default StartBreakAction
