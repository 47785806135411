import Vue from 'vue'
import merge from 'lodash/merge'
import withI18n from './with-i18n'
import withVuelidateField from './with-vuelidate'
import withVuetifyField from './with-vuetify'

export const withsDefaultAsComponent = [
  [withI18n, 'app.kiosk.forms'],
  [withVuelidateField, { errorKey: 'errorMessages' }],
  [withVuetifyField]
]

export const FORM_FIELDS_EVENTS = {
  change: 'change',
  blur: 'blur'
}

export const asComponent = (componentName, options = {}) => {
  const modelKey = options.modelKey || 'value'
  const mutateEventKey = options.event || 'input'
  const withs = (options.withs || withsDefaultAsComponent).map(
    ([withInit, withOptions]) => {
      return withInit(withOptions)
    }
  )

  const enhanceProps = (field, vm) => {
    return withs.reduce((props, withFn) => merge(props, withFn(field, vm)), {})
  }

  return {
    render(h, field, vm) {
      const componentOptions = {
        ...options,
        props: {
          ...options.props,
          ...enhanceProps(field, vm),
          [modelKey]: field.value,
          ...vm.$attrs
        },
        class: {
          [`field-${field.name}`]: true
        },
        attrs: {
          ...vm.$attrs,
          'data-heap-id': `field_${field.name}`
        },
        on: {
          [mutateEventKey]: (event) => {
            field.value = event
            field.$v.$touch()
            vm.$emit(mutateEventKey, event)
          },
          [FORM_FIELDS_EVENTS.blur]: (event) => {
            field.$v.$touch()
            vm.$emit(FORM_FIELDS_EVENTS.blur, event)
          }
        }
      }

      return h(componentName, componentOptions)
    }
  }
}

export const createFieldElement = (h, field, vm) => {
  const { render } = field.definition
  if (render) {
    return render(h, field, vm)
  }
  return null
}

export class FormField {
  constructor(name, state, definition) {
    this.name = name
    this.$renderer = createFieldElement
    this.definition = definition
    const $bus = (this.$bus = new Vue())

    this.on = (...args) => $bus.$on(...args)

    Object.defineProperties(this, {
      $v: {
        enumerable: true,
        get() {
          const { $v } = state.$formState.state
          return $v
        }
      },
      value: {
        enumerable: true,
        set(newData) {
          state.data = newData
          $bus.$emit(FORM_FIELDS_EVENTS.change, {
            name: this.name,
            value: state.data
          })
        },
        get() {
          return state.data
        }
      }
    })
  }

  setRenderer(renderer) {
    this.$renderer = renderer
  }

  render(h, vm) {
    return this.$renderer(h, this, vm)
  }

  static get as() {
    return {
      component: asComponent
    }
  }
}

export default FormField
