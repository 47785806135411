import FormState from './form-state'
import { FormControl, FORM_CONTROL_EVENTS } from './form-control'
import FormField from './form-field'

const isFunction = (src) => typeof src === typeof Function()

const getStateFromVuelidate = (vm, key = 'fields') => {
  return vm.$v[key].$model
}

const getValidationsFromVuelidate = (vm, key = 'fields') => {
  return vm.$v[key]
}

const getSubmitHandlerFromVm = (vm, key = 'submit') => {
  return (...args) => vm[key](...args)
}

export class FormBuilder {
  constructor(initialState, submitHandler, validations, i18n) {
    this.initialState = initialState
    this.submitHandler = submitHandler
    this.fields = {}
    this.$v = validations
    this.$i18n = i18n
  }

  setSubmitHandler(submitHandler) {
    this.submitHandler = submitHandler
    return this
  }

  setState(initialState) {
    this.initialState = initialState
    return this
  }

  setFields(fields = {}, FormFieldClass = FormField) {
    const getFields = isFunction(fields) ? fields : () => fields
    Object.entries(getFields(FormFieldClass.as)).reduce(
      ($fields, [name, description]) => {
        $fields[name] = (value) => new FormFieldClass(name, value, description)
        return $fields
      },
      this.fields
    )
    return this
  }

  fromComponent(vm, options) {
    const builder = FormBuilder.fromComponent(vm, options)
    builder.fields = this.fields
    return builder
  }

  build(name, { i18nPrefix = 'app.kiosk.forms' } = {}) {
    const formState = new FormState(this.initialState, { $v: this.$v })
    const formControl = new FormControl(name, formState, this.submitHandler)

    if (this.$vm) {
      Object.values(FORM_CONTROL_EVENTS).map((event) => {
        formControl.on(event, (...args) => this.$vm.$emit(event, ...args))
      })
    }

    formControl.$t = (key, args) =>
      this.$i18n.t(`${i18nPrefix}.${name}.${key}`, args)

    const attachStateToFields = () => {
      Object.entries(this.fields).forEach(([name, createField]) => {
        const partialState = new FormState(formState.get(name), {
          $v: this.$v[name]
        })
        formControl.setFields({ [name]: createField(partialState) })
      })
    }

    attachStateToFields()
    return formControl
  }

  static fromComponent(vm, options = {}) {
    const state = getStateFromVuelidate(vm, options.validationKey)
    const submitHandler = getSubmitHandlerFromVm(vm, options.submitKey)
    const validations = getValidationsFromVuelidate(vm, options.validationKey)
    const builder = new FormBuilder(state, submitHandler, validations, vm.$i18n)
    builder.$vm = vm
    return builder
  }
}

export default FormBuilder
