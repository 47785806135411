import Vue from 'vue'
import consola from 'consola'
import Visibility from 'visibilityjs'

export const onChangeVisibility = (callback, options = { immediate: true }) => {
  if (!Visibility.isSupported()) {
    consola.warn('Page Visibility API is not supported.')
    return
  }

  const state = Vue.observable({
    visibility: Visibility.state(),
    updatedAt: Date.now()
  })

  const setVisibilityState = () => {
    Object.assign(state, {
      visibility: Visibility.state(),
      updatedAt: Date.now()
    })
  }

  Visibility.change(() => {
    setVisibilityState()
    callback(state)
    consola.debug('Visibility changed to', state.visibility)
  })

  if (options.immediate) {
    callback(state)
  }

  return {
    get state() {
      return state
    },
    get updatedAt() {
      return state.updatedAt
    }
  }
}

export default onChangeVisibility
