<template>
  <VTabs v-model="selected" v-bind="$attrs">
    <VTab v-for="locale in locales" :key="locale.value">
      {{ locale.text }}
    </VTab>
  </VTabs>
</template>

<script>
export default {
  name: 'KioskLocaleTabs',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default() {
        return this.$i18n.locale
      }
    },
    locales: {
      type: Array,
      default() {
        return [
          { text: this.$t('app.kiosk.langs.en.default'), value: 'en' },
          { text: this.$t('app.kiosk.langs.fr.default'), value: 'fr' },
          { text: this.$t('app.kiosk.langs.es.default'), value: 'es' }
        ]
      }
    }
  },
  computed: {
    selected: {
      get() {
        return this.locales.findIndex((locale) => locale.value === this.value)
      },
      set(newSelected) {
        this.$emit('input', this.locales[newSelected].value)
      }
    }
  }
}
</script>
